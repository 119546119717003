import adapter from 'webrtc-adapter';
import { MediaDeviceNotFoundError, MediaPermissionError } from '../errors';
import { Devices, LocalMedia, LocalMediaAccessError, Media } from '../types';

/** A version of `navigator.enumerateDevices`, that throws if we don't have permission to access media devices */
const enumerateDevices = async (): Promise<MediaDeviceInfo[]> => {
	const { browserDetails } = adapter;
	let hasCameraPermission: boolean;
	let hasMicrophonePermission: boolean;

	const devices = await navigator.mediaDevices.enumerateDevices();

	const hasCamera = !!devices.find((d) => d.kind === 'videoinput');
	const hasMicrophone = !!devices.find((d) => d.kind === 'audioinput');

	if (browserDetails.browser === 'chrome' && (browserDetails.version ?? 0) >= 86) {
		hasCameraPermission =
			(await navigator.permissions.query({ name: 'camera' as never }))?.state === 'granted';
		hasMicrophonePermission =
			(await navigator.permissions.query({ name: 'microphone' as never }))?.state === 'granted';
	} else {
		hasCameraPermission = !!devices.find((d) => d.kind === 'videoinput' && d.deviceId !== '');
		hasMicrophonePermission = !!devices.find((d) => d.kind === 'audioinput' && d.deviceId !== '');
	}

	console.debug('Local media devices status', {
		hasCamera,
		hasMicrophone,
		hasCameraPermission,
		hasMicrophonePermission,
	});

	if (!hasCamera) {
		throw new MediaDeviceNotFoundError('camera');
	} else if (!hasMicrophone) {
		throw new MediaDeviceNotFoundError('microphone');
	} else if (!hasCameraPermission) {
		throw new MediaPermissionError('camera');
	} else if (!hasMicrophonePermission) {
		throw new MediaPermissionError('microphone');
	}

	return devices;
};

/** Custom getUserMedia implementation that allows matching by name of preferred devices */
const getUserMedia = async (
	constraints: MediaStreamConstraints,
	preferredDevices: NonNullable<Devices> | null
): Promise<Media> => {
	const allMediaDevices = await enumerateDevices();

	const mediaConstraints = { ...constraints };

	let audioOutputId: string | undefined;

	const {
		camera: preferredCamera,
		microphone: preferredMicrophone,
		speaker: preferredSpeakers,
	} = preferredDevices ?? {};

	const prefersSpecificMediaDevices = !!(
		preferredCamera?.name ||
		preferredMicrophone?.name ||
		preferredSpeakers?.name
	);

	if (prefersSpecificMediaDevices) {
		console.debug('PreferredDevices:', preferredDevices);

		const preferredCameraId = allMediaDevices.find(
			(device) => device.kind === 'videoinput' && device.label === preferredCamera?.name
		)?.deviceId;
		if (preferredCameraId) {
			mediaConstraints.video = {
				...(typeof mediaConstraints.video === 'boolean' ? {} : mediaConstraints.video),
				deviceId: { exact: preferredCameraId },
			};
		}

		const preferredMicId = allMediaDevices.find(
			(device) => device.kind === 'audioinput' && device.label === preferredMicrophone?.name
		)?.deviceId;
		if (preferredMicId) {
			mediaConstraints.audio = {
				...(typeof mediaConstraints.audio === 'boolean' ? {} : mediaConstraints.audio),
				deviceId: { exact: preferredMicId },
			};
		}

		audioOutputId = allMediaDevices.find(
			(device) => device.kind === 'audiooutput' && device.label === preferredSpeakers?.name
		)?.deviceId;
	} else {
		console.debug('No PreferredDevices');
	}

	const stream = await navigator.mediaDevices
		.getUserMedia(mediaConstraints)
		.then((stream) => {
			console.debug('getUserMedia() -> from preferredMediaDevices');
			return stream;
		})
		.catch((error) => {
			console.error(`getUserMedia() -> from preferredMediaDevices`, error);
			return navigator.mediaDevices
				.getUserMedia({ audio: true, video: true })
				.then((stream) => {
					console.log('getUserMedia() -> from generic constraints');
					return stream;
				})
				.catch((error) => {
					console.error('getUserMedia() -> from generic constraints', error);
					throw error;
				});
		});

	return { label: 'local', stream, audioOutputId };
};

/** Hook that auto-gets user media when mounted. */
export const useLocalMedia = async (
	constraints: MediaStreamConstraints,
	preferredDevices: NonNullable<Devices> | null,
	onLocalMediaError: (error: LocalMediaAccessError) => void
): Promise<LocalMedia> => {
	let media = null,
		error = null;
	try {
		media = await getUserMedia(constraints, preferredDevices);
	} catch (e: any) {
		error = e;
		media = {
			label: 'local',
		};
		onLocalMediaError(e);
	}

	return { media, error } as LocalMedia;
};
