import { useHotkeys } from 'react-hotkeys-hook';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
	SettingsController,
} from './useSettingsController';

type Props = {
	settingsController: SettingsController;
};

const useHotkeyController = ({ settingsController: { settings, setSettingValue } }: Props) => {
	const getKeyString = (keyArray: string[][]) =>
		keyArray.map((keyCombo) => keyCombo.join('+')).join(', ');

	useHotkeys(
		getKeyString([
			['ctrl', 'e'],
			['command', 'e'],
		]),
		(e: KeyboardEvent) => {
			e.preventDefault();
			const prevValue = settings[SettingPageSectionHeaders.EXPERIMENTAL].hideHeader;
			settings[SettingPageSectionHeaders.EXPERIMENTAL].hideHeader = !prevValue;

			console.log('Hotkey fired: toggling experimental', prevValue);
			setSettingValue(SettingHeaders.ENABLE_EXPERIMENTAL, prevValue ? undefined : !prevValue);
		},
		{
			preventDefault: true,
		}
	);

	useHotkeys(
		getKeyString([
			['ctrl', 'alt', 'a'],
			['command', 'option', 'a'],
		]),
		(e: KeyboardEvent) => {
			e.preventDefault();
			const prevValue = settings[SettingPageSectionHeaders.ADMIN].hideHeader;
			settings[SettingPageSectionHeaders.ADMIN].hideHeader = !prevValue;

			console.log('Hotkey fired: toggling admin', prevValue);
			setSettingValue(SettingHeaders.ENABLE_ADMIN, prevValue ? undefined : !prevValue);
		},
		{
			preventDefault: true,
		}
	);
};

export default useHotkeyController;
