import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Slider from '../slider';
import './index.scss';

import { setParameter } from 'actions/setParam';
import { SET_NAV_SPEED } from 'actions/types';
import { AppRootState } from 'reducers';
import Tooltip from '../tooltip';

import { AppContext } from 'context/appContext';
import { SessionState } from 'GoBeWebRTC/types';
import genericAlertIcon from 'images/alert.svg';
import redMicOffIcon from 'images/mic-off-red.svg';
import micIcon from 'images/mic.svg';
import redAlertIcon from 'images/red-alert.svg';
import whiteSpeedIcon from 'images/white-speed.svg';
import whiteStopIcon from 'images/white-stop.svg';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';

const reduxConnector = connect(
	(state: AppRootState) => ({
		controlDataChannel: state.sessionState.controlDataChannel,
		navSpeed: state.sessionState.navSpeed,
		hideNavOptionsStatus: state.sessionState.hideNavOptionsStatus,
		isAutoParking: state.sessionState.isAutoParking,
		isAbruptlyClosing: state.sessionState.isAbruptlyClosing,
	}),
	{ setParameter }
);

type PropsFromParent = {
	sessionState: SessionState;
	isStreamSynced: boolean;
	onClickHangUp: () => void;
	localStream: MediaStream | null;
	hasPrimaryVideoStartedPlaying: boolean;
	isDrivingImpaired: boolean;
	penalty: number;
	initialVolume?: number;
};

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const SessionOptions: React.FC<ComponentProps> = ({
	sessionState,
	localStream,
	isStreamSynced,
	onClickHangUp,
	hasPrimaryVideoStartedPlaying,
	controlDataChannel,
	navSpeed,
	hideNavOptionsStatus,
	setParameter,
	isAutoParking,
	isDrivingImpaired,
	penalty,
	isAbruptlyClosing,
}: ComponentProps) => {
	const {
		navController: {},
		settingsController,
	} = useContext(AppContext);
	const localVoiceRef = useRef<any>(null);
	const volBeforePause = useRef<number>(0);
	const [localVoiceValue, changeLocalVoiceValue] = useState<any>(
		settingsController.settings[SettingPageSectionHeaders.ADMIN].children[SettingPageHeaders.MISC]
			.children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
			SettingHeaders.LOCAL_VOICE_VOLUME
		].value
	);
	const icon = useMemo(() => (penalty === 1 ? redAlertIcon : genericAlertIcon), [penalty]);

	const onLocalVoiceChange = (value: number) => {
		settingsController.setSettingValue(SettingHeaders.LOCAL_VOICE_VOLUME, value);
		changeLocalVoiceValue(value);
	};

	const controlDataChannelRef = useRef(controlDataChannel);
	useEffect(() => {
		controlDataChannelRef.current = controlDataChannel;
	}, [controlDataChannel]);

	// Ensure that volume is sent regardless of when this is called
	function sendVolumeChange(value: number) {
		if (controlDataChannelRef.current && controlDataChannelRef.current.readyState === 'open') {
			controlDataChannelRef.current.send(`VOL ${value}`);
			controlDataChannelRef.current.send(JSON.stringify({ type: 'change_volume', volume: value }));
		} else {
			setTimeout(() => sendVolumeChange(value), 1000);
		}
	}

	useEffect(() => {
		sendVolumeChange(localVoiceValue);
	}, [localVoiceValue]);

	const onLocalVoiceIconClick = () => {
		if (
			hasPrimaryVideoStartedPlaying &&
			controlDataChannel &&
			controlDataChannel.readyState === 'open'
		) {
			if (localVoiceValue === 0) {
				onLocalVoiceChange(volBeforePause.current);
			} else {
				volBeforePause.current = localVoiceValue;
				onLocalVoiceChange(0);
			}
		}
	};

	const onNavSpeedChange = (value: number) => {
		setParameter('navSpeed', SET_NAV_SPEED, value);
		settingsController.setSettingValue(SettingHeaders.NAV_SPEED, value);
	};

	const isSessionOpen =
		hasPrimaryVideoStartedPlaying && controlDataChannel && controlDataChannel.readyState === 'open';

	useEffect(() => {
		if (sessionState === 'InProgress' && localStream && isStreamSynced) {
			if (parseInt(localVoiceValue) === 0) {
				localStream.getAudioTracks()[0].enabled = false;
			} else {
				localStream.getAudioTracks()[0].enabled = true;
			}
		}
	}, [isStreamSynced, localStream, localVoiceValue, sessionState]);

	const audioLvlWarningThreshold = 75;
	const localVoiceClass =
		parseInt(localVoiceValue) > audioLvlWarningThreshold
			? 'localVoiceText warning'
			: 'localVoiceText';

	return (
		<div className={hideNavOptionsStatus ? 'sessionOptionsContainer' : 'displayNone'}>
			<div
				className={`roundedButton ${
					!isSessionOpen || isAutoParking ? 'disabled' : 'rectangle navSpeedRectangle'
				}`}
			>
				<div>
					<img className="iconWrapper" src={whiteSpeedIcon} alt="" />
					{isDrivingImpaired ? <img className="badge" src={icon} alt="" /> : null}
				</div>
				{isSessionOpen && !isAutoParking && (
					<>
						<div className="localVoiceText">
							{((3.2 * parseInt(navSpeed)) / 100).toString().slice(0, 3)}
							<span>km/h</span>
						</div>
						<div className="rectangleLocalVoice">
							<Slider
								onChange={onNavSpeedChange}
								value={navSpeed}
								icon="speed-green.svg"
								id="navVideoSpeed"
								minValue={Math.ceil((0.6 * 100) / 3.2)}
							/>
						</div>
					</>
				)}
			</div>
			<div className={`roundedButton ${isSessionOpen ? 'rectangle' : 'disabled'}`}>
				<img
					className="iconWrapper"
					onClick={onLocalVoiceIconClick}
					src={parseInt(localVoiceValue) === 0 ? redMicOffIcon : micIcon}
					alt=""
				/>
				{isSessionOpen && (
					<>
						<div className={localVoiceClass}>{localVoiceValue}%</div>
						<div className="rectangleLocalVoice" ref={localVoiceRef}>
							<Slider
								onChange={onLocalVoiceChange}
								value={localVoiceValue}
								icon={parseInt(localVoiceValue) === 0 ? 'mic-off-red.svg' : 'mic-off-green.svg'}
								id="localVideoVolume"
								useWarningThreshold
								warningThreshold={audioLvlWarningThreshold}
							/>
						</div>
					</>
				)}
			</div>
			<Tooltip text="Pause/End" disabled={isAutoParking}>
				<div
					className={`roundedButton ${isAutoParking ? 'disabled' : 'red'}`}
					onClick={() => {
						if (!isAutoParking) onClickHangUp();
					}}
				>
					<img className="iconWrapper" src={whiteStopIcon} alt="" />
					{isAbruptlyClosing ? (
						<div className="circleRipple">
							<div className="circleRippleEffect" />
						</div>
					) : null}
				</div>
			</Tooltip>
		</div>
	);
};

export default reduxConnector(SessionOptions);
