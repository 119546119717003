import { setParameter } from 'actions/setParam';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';
import './index.scss';
import Draggable from 'components/draggable';
import { AppContext } from 'context/appContext';
import { RobotStatus, SessionState } from 'GoBeWebRTC/types';
import { DragMode } from 'types';
import confirm from 'images/confirm.svg';
import clear from 'images/clear.svg';
import expand from 'images/add-outline.svg';
import collapse from 'images/remove.svg';

type PropsFromParent = {
	shareUrl: (arg: string) => boolean;
	robotStatus: RobotStatus | null;
	// controlDataChannel: RTCDataChannel | null;
	sessionState: SessionState;
};

const reduxConnector = connect(
	(state: AppRootState) => ({
		dragMode: state.sessionState.dragMode,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const UrlShare: React.FC<ComponentProps> = ({ shareUrl, robotStatus, sessionState, dragMode }) => {
	const {
		navController: { enable: enableNavController, disable: disableNavController },
	} = useContext(AppContext);
	const [statusMessage, setStatusMessage] = useState<string>('');

	const [isMinimized, setIsMinimized] = useState<boolean>(false);
	const [currentUrl, setCurrentUrl] = useState<string>('');
	const [displayedUrl, setDisplayedUrl] = useState<string>('');

	function isValidUrlCheck(url: string) {
		// TODO: This must be way more robust
		// https://www.dmi.dk/lokation/show/DK/2615876/Odense/

		try {
			let test = new URL(url);
			if (test.hostname.includes('.')) {
				return true;
			} else {
				return false;
			}
		} catch (e) {
			return false;
		}
	}

	const [isValidUrl, setIsValidUrl] = useState<boolean>(false);
	useEffect(() => {
		setIsValidUrl(isValidUrlCheck(currentUrl));
	}, [currentUrl]);

	useEffect(() => {
		shareUrl(currentUrl);

		return () => {
			shareUrl('');
		};
	}, []);

	return (
		<Draggable
			mode={dragMode as any}
			content={
				<div className="urlShareContainer">
					<div className="urlShareTitle">
						<div className="urlShareMinimize" onClick={() => setIsMinimized((prev) => !prev)}>
							<img className="urlShareMinimizeIcon" src={isMinimized ? expand : collapse} alt="" />
						</div>
						<b>URL Sharing</b>
					</div>
					{!isMinimized && (
						<>
							<div className="HorizontalLine" />
							<div className="urlShareInput">
								<div className={`roundedButton ${isValidUrl ? 'green' : 'disabled'}`}>
									<img
										className="roundedButtonIcon"
										onClick={() => {
											console.log('share');
											if (shareUrl(currentUrl)) {
												setDisplayedUrl(currentUrl);
											}
										}}
										src={confirm}
										alt=""
									/>
								</div>
								<input
									placeholder="Enter URL to share"
									onChange={(e) => {
										const url = e.target.value;
										if (!isMinimized) {
											setCurrentUrl(url);
										}
									}}
									onDoubleClick={(e) => {
										(e.target as HTMLInputElement).select();
									}}
									value={currentUrl}
								/>
							</div>
							<div className="HorizontalLine" />
							<div className="urlShareInput">
								<div className={`roundedButton ${displayedUrl === '' ? 'disabled' : 'red'}`}>
									<img
										className="roundedButtonIcon"
										onClick={() => {
											let succes = false;
											// Make sure the URL reaches the robot
											const shareUrlRepeatedly = () => {
												succes = shareUrl('');
												if (!succes) {
													setTimeout(shareUrlRepeatedly, 1000);
												} else {
													setDisplayedUrl('');
												}
											};
											shareUrlRepeatedly();
										}}
										src={clear}
										alt=""
									/>
								</div>
								<input placeholder="URL currently shared" disabled value={displayedUrl} />
							</div>
							<div className="HorizontalLine" />
							<div className="urlShareTitle" />
						</>
					)}
				</div>
			}
			style={{
				zIndex: 2,
				bottom: `50%`,
				right: 135,
			}}
		/>
	);
};

export default reduxConnector(UrlShare);
