import React, { useState, useRef, useEffect } from 'react';
import { PrimaryCameraState, RobotPrimaryCamera } from '../../../../types';
import './selectLocalCameras.scss';
import openVideoPreview from '../../../../images/open-video-preview.svg';
import closeVideoPreview from '../../../../images/close-video-preview.svg';

interface SelectLocalCamerasProps {
	isHovered: boolean;
	primaryMedia: MediaStream | null;
	primaryRotation: number;
	navMedia: MediaStream | null;
	navRotation: number;
	setLocalDisplays: ({}) => void;
	primaryCameraState: PrimaryCameraState;
	shouldShowLoadingIndicator: boolean;
}

export const SelectLocalCameras: React.FC<SelectLocalCamerasProps> = ({
	isHovered,
	primaryMedia,
	primaryRotation,
	navMedia,
	navRotation,
	setLocalDisplays,
	primaryCameraState,
	shouldShowLoadingIndicator,
}) => {
	const layoutOptions = {
		regular: {
			videoWidth: 41,
			borderRadius: 4,
			wideHeight: 23,
			navHeight: 31,
			UpperBottom: 37,
			LowerBottom: 0,
		},
		expanded: {
			videoWidth: 75,
			borderRadius: 10,
			wideHeight: 75,
			navHeight: 75,
			UpperBottom: 85,
			LowerBottom: 0,
		},
	};

	const [wideVideoVisible, setWideVideoVisible] = useState(true);
	const [navVideoVisible, setNavVideoVisible] = useState(true);

	const [wideManipIconVisible, setWideManipIconVisible] = useState(false);
	const [navManipIconVisible, setNavManipIconVisible] = useState(false);

	const [videoArrangement, setVideoArrangement] = useState([
		['block', layoutOptions.regular.UpperBottom], //Wide cam
		['block', layoutOptions.regular.LowerBottom], //Nav cam
	]);
	useEffect(() => {
		if (!shouldShowLoadingIndicator) {
			if (wideVideoVisible && !navVideoVisible && !isHovered) {
				setVideoArrangement([
					['block', layoutOptions.regular.LowerBottom],
					['none', layoutOptions.regular.LowerBottom],
				]);
			} else if (navVideoVisible && !wideVideoVisible && !isHovered) {
				setVideoArrangement([
					['none', layoutOptions.regular.LowerBottom],
					['block', layoutOptions.regular.LowerBottom],
				]);
			} else if (!navVideoVisible && !wideVideoVisible && !isHovered) {
				setVideoArrangement([
					['none', layoutOptions.regular.LowerBottom],
					['none', layoutOptions.regular.LowerBottom],
				]);
			} else {
				setVideoArrangement([
					[
						'block',
						isHovered ? layoutOptions.expanded.UpperBottom : layoutOptions.regular.UpperBottom,
					],
					[
						'block',
						isHovered ? layoutOptions.expanded.LowerBottom : layoutOptions.regular.LowerBottom,
					],
				]);
			}
		}
	}, [wideVideoVisible, navVideoVisible, isHovered]);

	const counter = useRef(0);
	useEffect(() => {
		// Starting interval must wait until after mqtt has been initialized
		let intervalId: NodeJS.Timeout;
		if (counter.current > 0) {
			intervalId = setInterval(() => {
				const element = document.querySelector('.react-transform-component');

				if (element) {
					const width = element.offsetWidth;
					const height = element.offsetHeight;

					const matches = element.style.transform.match(/-?\d+(\.\d+)?/g);
					const numbers = matches ? matches.map(Number) : [0, 0, 1];
					const x = numbers[0];
					const y = numbers[1];
					const scale = numbers[2];

					setLocalDisplays({
						wide: wideVideoVisible,
						wideZoomStyle: { x, y, scale },
						wideOriginalSize: { width, height },
						wideIsZoomed: primaryCameraState.currentPrimaryCamera === RobotPrimaryCamera.ZOOM_CAM,
						nav: navVideoVisible,
					});
				}
			}, 100);
		} else {
			setTimeout(() => {
				counter.current += 1;
			}, 3000);
		}

		return () => clearInterval(intervalId);
	}, [wideVideoVisible, navVideoVisible, primaryCameraState, counter.current, setLocalDisplays]);

	const wideVideoRef = useRef<HTMLVideoElement | null>(null);
	useEffect(() => {
		if (wideVideoRef.current && primaryMedia) {
			wideVideoRef.current.srcObject = primaryMedia;
		}
	}, [primaryMedia]);

	const navVideoRef = useRef<HTMLVideoElement | null>(null);
	useEffect(() => {
		if (navVideoRef.current && navMedia) {
			navVideoRef.current.srcObject = navMedia;
		}
	}, [navMedia]);

	return (
		<div className="localCamConfigContainer">
			<div
				className="localCamConfig wideContainer"
				style={{
					display: videoArrangement[0][0] as string,
					bottom: videoArrangement[0][1] + 'px',
					height:
						(isHovered ? layoutOptions.expanded.wideHeight : layoutOptions.regular.wideHeight) +
						'px',
					width:
						(isHovered ? layoutOptions.expanded.videoWidth : layoutOptions.regular.videoWidth) +
						'px',
					borderRadius:
						(isHovered ? layoutOptions.expanded.borderRadius : layoutOptions.regular.borderRadius) +
						'px',
				}}
				onMouseEnter={() => setWideManipIconVisible(true)}
				onMouseLeave={() => setWideManipIconVisible(false)}
			>
				<div
					className={` ${
						isHovered && !wideVideoVisible && !shouldShowLoadingIndicator
							? 'hoverOutline'
							: 'displayNone'
					}`}
					style={{
						borderRadius:
							(isHovered
								? layoutOptions.expanded.borderRadius
								: layoutOptions.regular.borderRadius) + 'px',
					}}
				/>
				<video
					ref={wideVideoRef}
					className={`${
						wideVideoVisible && !shouldShowLoadingIndicator ? 'localVideo' : 'displayNone'
					}`}
					style={{
						transform: `rotate(${primaryRotation}deg)`,
						borderRadius:
							(isHovered
								? layoutOptions.expanded.borderRadius
								: layoutOptions.regular.borderRadius) + 'px',
					}}
					playsInline
					autoPlay
					loop
					muted
				/>
				{shouldShowLoadingIndicator && (
					<div className="localVideo">
						<div className="localLoading" />
					</div>
				)}
				<div
					className={`${
						wideManipIconVisible && !shouldShowLoadingIndicator
							? 'manipIconContainer'
							: 'displayNone'
					}`}
					style={{
						borderRadius:
							(isHovered
								? layoutOptions.expanded.borderRadius
								: layoutOptions.regular.borderRadius) + 'px',
					}}
					onClick={() => {
						setWideVideoVisible(!wideVideoVisible);
					}}
				>
					<img
						src={wideVideoVisible ? closeVideoPreview : openVideoPreview}
						className="manipIcon"
					/>
				</div>
			</div>
			<div
				className="localCamConfig navContainer"
				style={{
					display: videoArrangement[1][0] as string,
					bottom: videoArrangement[1][1] + 'px',
					height:
						(isHovered ? layoutOptions.expanded.navHeight : layoutOptions.regular.navHeight) + 'px',
					width:
						(isHovered ? layoutOptions.expanded.videoWidth : layoutOptions.regular.videoWidth) +
						'px',
					borderRadius:
						(isHovered ? layoutOptions.expanded.borderRadius : layoutOptions.regular.borderRadius) +
						'px',
				}}
				onMouseEnter={() => setNavManipIconVisible(true)}
				onMouseLeave={() => setNavManipIconVisible(false)}
			>
				<div
					className={` ${
						isHovered && !navVideoVisible && !shouldShowLoadingIndicator
							? 'hoverOutline'
							: 'displayNone'
					}`}
					style={{
						borderRadius:
							(isHovered
								? layoutOptions.expanded.borderRadius
								: layoutOptions.regular.borderRadius) + 'px',
					}}
				/>
				<video
					ref={navVideoRef}
					className={`${
						navVideoVisible && !shouldShowLoadingIndicator ? 'localVideo' : 'displayNone'
					}`}
					style={{
						transform: `rotate(${navRotation}deg)`,
						borderRadius:
							(isHovered
								? layoutOptions.expanded.borderRadius
								: layoutOptions.regular.borderRadius) + 'px',
					}}
					playsInline
					autoPlay
					loop
					muted
				/>
				{shouldShowLoadingIndicator && (
					<div className="localVideo">
						<div className="localLoading" />
					</div>
				)}
				<div
					className={`${
						navManipIconVisible && !shouldShowLoadingIndicator
							? 'manipIconContainer'
							: 'displayNone'
					}`}
					style={{
						borderRadius:
							(isHovered
								? layoutOptions.expanded.borderRadius
								: layoutOptions.regular.borderRadius) + 'px',
					}}
					onClick={() => {
						setNavVideoVisible(!navVideoVisible);
					}}
				>
					<img src={navVideoVisible ? closeVideoPreview : openVideoPreview} className="manipIcon" />
				</div>
			</div>
		</div>
	);
};
