import backImage from 'images/back.svg';
import checkImage from 'images/check.svg';
import React, { useState, useEffect, useRef } from 'react';
import './index.scss';

let settingsSelectInstances = 0;

export type SelectValue = {
	name: string;
	value: any;
};
interface Props {
	selectedValue?: SelectValue;
	values: SelectValue[];
	onChange?: (data: SelectValue) => any;
}

const Select: React.FC<Props> = ({ selectedValue, values, onChange }) => {
	const [active, setActive] = useState<boolean>(false);
	const [activePmr, setActivePmr] = useState<SelectValue>(selectedValue!);

	const _handleClick = () => {
		setActive((value) => !value);
	};

	const _handleChange = (data: SelectValue) => (e: any) => {
		setActivePmr(data);
		_handleClick();
		onChange!(data);
	};

	const thisInstanceNumber = useRef(0);
	useEffect(() => {
		settingsSelectInstances++;
		thisInstanceNumber.current = settingsSelectInstances;
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			const selectContainers = document.querySelectorAll('.options-container');

			selectContainers.forEach((container) => {
				if (!target.closest(`#${'selectContainer' + thisInstanceNumber.current}`)) {
					setActive(false);
				}
			});
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<div className="selectContainer" id={'selectContainer' + thisInstanceNumber.current}>
			<div className="select-box">
				<div className={`options-container ${active ? 'active' : ''}`}>
					{values.map((data) => {
						return (
							<div
								key={data.name}
								className={`${
									data.name === activePmr?.name ? 'option-container-disabled' : 'option-container'
								}`}
							>
								<div className="option">
									<input
										type="radio"
										id={data.value}
										name="category"
										value={data.value}
										onChange={_handleChange(data)}
										disabled={data.name === activePmr?.name}
									/>
									<label htmlFor={data.value}>
										<div
											className={`${data.name === activePmr?.name ? 'option-label-disabled' : ''}`}
										>
											{data.name}
										</div>
										<div className="check">
											{data.name === activePmr?.name ? (
												<img src={checkImage} alt="check" width="15" />
											) : (
												''
											)}
										</div>
									</label>
								</div>
							</div>
						);
					})}
				</div>
				<div className="wrapper-select">
					<div className="selected" onClick={_handleClick}>
						<div>{activePmr ? activePmr.name : 'Select'}</div>
						<img src={backImage} width="15" alt="arrow-down" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Select;
