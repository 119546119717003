export const SET_DATA_CHANNEL = 'SET_DATA_CHANNEL';
export const SET_BATTERY_STATUS = 'SET_BATTERY_STATUS';

export const SET_FULL_SCREEN_STATUS = 'SET_FULL_SCREEN_STATUS';
export const SET_HIDE_NAV_OPTIONS_STATUS = 'SET_HIDE_NAV_OPTIONS_STATUS';
export const SET_DRIVING_MODE_STATUS = 'SET_DRIVING_MODE_STATUS';
export const SET_NAV_CAMERA_HEIGHT = 'SET_NAV_CAMERA_HEIGHT';

export const SET_NAV_SPEED = 'SET_NAV_SPEED';
export const SET_REMOTE_VOICE_VOLUME = 'SET_REMOTE_VOICE_VOLUME';

export const UPDATE_MICROPHONE = 'UPDATE_MICROPHONE';
export const UPDATE_SPEAKER = 'UPDATE_SPEAKER';
export const UPDATE_CAMERA = 'UPDATE_CAMERA';

export const SET_ROBOT_INFO = 'SET_ROBOT_INFO';
export const SET_AUTO_PARK_ENABLED = 'SET_AUTO_PARK_ENABLED';
export const SET_CONFIRM_AUTO_PARK = 'SET_CONFIRM_AUTO_PARK';
export const SET_IS_AUTO_PARKING = 'SET_IS_AUTO_PARKING';
export const SET_DOCK_CONTROLLER_STATUS = 'SET_DOCK_CONTROLLER_STATUS';
export const SET_MINIMIZATION_IMPAIRED_DRIVING = 'SET_MINIMIZATION_IMPAIRED_DRIVING';
export const SET_ROBOT_STATUS = 'SET_ROBOT_STATUS';
export const SET_CAMERA_CALIBRATION_TOOL_ENABLED = 'SET_CAMERA_CALIBRATION_TOOL_ENABLED';
export const SET_CAMERA_CALIBRATION_DEVIATION_STEP = 'SET_CAMERA_CALIBRATION_DEVIATION_STEP';
export const SET_DRAG_MODE = 'SET_DRAG_MODE';
export const SET_REMOTE_VIDEO_AURA_STYLE = 'SET_REMOTE_VIDEO_AURA_STYLE';
export const SET_IS_NAVIGATING = 'SET_IS_NAVIGATING';
export const SET_iS_ABRUPTLY_CLOSING = 'SET_iS_ABRUPTLY_CLOSING';
