import React, { useEffect, useState, useContext, useRef } from 'react';
import { AppContext } from 'context/appContext';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import 'aframe';

const RemoteNavOverlay = () => {
	const { settingsController } = useContext(AppContext);
	const shouldShow = useRef(true);
	useEffect(() => {
		shouldShow.current =
			settingsController.settings['App settings'].children[
				SettingPageHeaders.AUGMENTED_REALITY
			].children[SettingTabHeaders.GENERAL].children[
				SettingSectionHeaders.AUGMENTED_REALITY
			].children[SettingHeaders.AR_ENABLE_NAV_LINES].value;
	}, [settingsController.settings]);

	return (
		<div
			id="primary_scene_container"
			style={{
				zIndex: 10,
				position: 'absolute',
				width: '100%',
				height: '100%',
				display: shouldShow.current ? 'block' : 'none',
			}}
		>
			<a-scene id="primary_scene" embedded xr-mode-ui="enabled: false">
				<a-entity id="top_rig" position="0 1.57 0" rotation="-21 0 0">
					<a-camera
						id="front_camera"
						fov={(1080 / Math.sqrt(1920 * 1920 + 1080 * 1080)) * 170}
						zoom="1"
						position="0 0 0"
						active="true"
						look-controls="enabled: false"
						wasd-controls="enabled: false"
					/>
				</a-entity>
				<a-entity
					id="primary_floor"
					geometry="primitive: plane; height: 10; width: 10"
					position="0 0 0"
					rotation="-90 0 0"
					material="color: green; opacity: 0"
				>
					<a-entity
						class="navigation-line"
						navigation-line="startPoint: -0.225 0; linewidth: 9; shadow: true"
					/>
					<a-entity
						class="navigation-line"
						navigation-line="startPoint: 0.225 0; linewidth: 9; shadow: true"
					/>
				</a-entity>
			</a-scene>
		</div>
	);
};

export default RemoteNavOverlay;
