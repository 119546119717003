// Import dependencies
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import * as bodyPix from '@tensorflow-models/body-pix';
import { AppContext } from 'context/appContext';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import backgroundImg from 'images/backgroundImg.jpg';
import { useContext, useEffect, useRef } from 'react';
import './index.scss';

export enum ObjectSegmentationLibraries {
	MEDIAPIPE = 'mediapipe',
	BODY_PIX = 'tensorflow/body-pix',
}

type ObjectSegmentationCallbacks = {};
type ObjectSegmentationOptions = {};

class _MEDIAPIPE_LIBRARY_CONTROLLER {
	private selfieSegmentation: SelfieSegmentation;

	/** On results from media pipe */
	private onResults = (results: any) => {
		const ctx = this.canvasRef?.current?.getContext('2d');
		ctx?.save();
		ctx?.clearRect(
			0,
			0,
			this.canvasRef?.current?.width as number,
			this.canvasRef?.current?.height as number
		);
		ctx?.drawImage(
			results.segmentationMask,
			0,
			0,
			this.canvasRef?.current?.width!,
			this.canvasRef?.current?.height!
		);
		// Only overwrite existing pixels.
		ctx!.globalCompositeOperation = 'source-out';
		ctx!.fillStyle = '#fff';
		ctx?.fillRect(0, 0, this.canvasRef?.current?.width!, this.canvasRef?.current?.height!);

		// Only overwrite missing pixels.
		ctx!.globalCompositeOperation = 'destination-over';
		ctx?.drawImage(
			results.image,
			0,
			0,
			this.canvasRef?.current?.width!,
			this.canvasRef?.current?.height!
		);

		ctx?.restore();
	};

	public load = () => {
		/** Mediapipe selfie segmentation */
		this.selfieSegmentation = new SelfieSegmentation({
			locateFile: (file: string) =>
				`https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
		});
		this.selfieSegmentation.setOptions({
			modelSelection: 1,
			selfieMode: true,
		});
		console.log('Object segmentation model loaded: Mediapipe');
		this.start();
	};

	public start = () => {
		if (this.selfieSegmentation) {
			const sendToMediaPipe = async () => {
				if (!this.video?.videoWidth) {
					requestAnimationFrame(sendToMediaPipe);
				} else {
					await this.selfieSegmentation.send({ image: this.video });
					requestAnimationFrame(sendToMediaPipe);
				}
			};
			sendToMediaPipe();
			this.selfieSegmentation.onResults(this.onResults);
			console.log('Object segmentation model started: MediaPipe');
		}
	};

	public unload = () => {
		this.selfieSegmentation.close();
		console.log('Object segmentation model unloaded: MediaPipe');
	};

	constructor(
		public video: any,
		public dimensions: { width: number; height: number },
		public canvasRef: React.RefObject<HTMLCanvasElement>,
		public callbacks: ObjectSegmentationCallbacks,
		public options: ObjectSegmentationOptions
	) {}
}

class _BODYPIX_LIBRARY_CONTROLLER {
	private net: bodyPix.BodyPix;
	private canvasContext: CanvasRenderingContext2D;
	private segmentationMask: ImageData = new ImageData(
		this.dimensions.width,
		this.dimensions.height
	);
	private segmentationMaskCanvas: any = document.createElement('canvas');
	private blurredEnabled: boolean = true;
	private virtualBackgroundEnabled: boolean = false;
	private backgroundBlurRange: number = 6;
	private edgeBlurRange: number = 7;
	private selectedBackground: any = document.createElement('img');

	private blurBackground = (image: any, blurAmount: number) => {
		const { width, height } = this.dimensions;
		const ctx = this.canvasContext;
		ctx!.globalCompositeOperation = 'destination-over';
		ctx!.filter = `blur(${blurAmount}px)`;
		ctx!.drawImage(image, 0, 0, width, height);
	};

	private runPostProcessing = (image: any, segmentation: any, blurAmount: number) => {
		const { width, height } = this.dimensions;
		const ctx = this.canvasContext;
		ctx?.clearRect(0, 0, width, height);

		ctx!.globalCompositeOperation = 'copy';
		ctx!.filter = 'none';

		if (this.blurredEnabled || this.virtualBackgroundEnabled) {
			ctx!.filter = `blur(${this.edgeBlurRange}px)`;
			ctx!.drawImage(segmentation, 0, 0, width, height);
			ctx!.globalCompositeOperation = 'source-in';
			ctx!.filter = 'none';
		}

		ctx!.drawImage(image, 0, 0, width, height);

		if (this.virtualBackgroundEnabled) {
			this.blurBackground(this.selectedBackground, 0);
		}

		if (this.blurredEnabled) {
			this.blurBackground(image, blurAmount);
		}

		ctx!.restore();
	};

	private perform = async () => {
		while (this.blurredEnabled || this.virtualBackgroundEnabled) {
			const ctx = this.segmentationMaskCanvas?.getContext('2d');
			ctx?.clearRect(0, 0, this.dimensions.width, this.dimensions.height);
			ctx?.drawImage(this.video, 0, 0, this.dimensions.width, this.dimensions.height);

			const segmentation = await this.net.segmentPerson(this.segmentationMaskCanvas, {
				//maxDetections: 1,
				// scoreThreshold: 0.1,
				// nmsRadius: 1,
				// internalResolution: 0.1,
				// segmentationThreshold: 0.1,
			});
			for (let i = 0; i < this.dimensions.width * this.dimensions.height; i++) {
				// Sets only the alpha component of each pixel
				this.segmentationMask.data[i * 4 + 3] = segmentation.data[i] ? 255 : 0;
			}

			ctx?.putImageData(this.segmentationMask, 0, 0);

			this.runPostProcessing(this.video, this.segmentationMaskCanvas, this.backgroundBlurRange);
		}
	};

	public load = () => {
		this.segmentationMaskCanvas.width = this.dimensions.width;
		this.segmentationMaskCanvas.height = this.dimensions.height;
		this.selectedBackground.src = backgroundImg;
		try {
			bodyPix
				.load
				// 	{
				// 	// architecture: 'MobileNetV1',
				// 	// outputStride: 8,
				// 	// multiplier?: BodyPixMultiplier;
				// 	// modelUrl?: string;
				// 	// quantBytes?: BodyPixQuantBytes;
				// }
				()
				.then((res) => {
					this.net = res;
					this.canvasContext = this.canvasRef.current?.getContext('2d')!;
					this.start();
				});
			console.log('Object segmentation model loaded: BodyPix');
		} catch (err) {
			console.log(err);
		}
	};

	public start = () => {
		this.perform();
		console.log('Object segmentation model started: BodyPix');
	};

	public unload = () => {
		console.log('Object segmentation model unloaded: BodyPix');
	};

	constructor(
		public video: any,
		public dimensions: { width: number; height: number },
		public canvasRef: React.RefObject<HTMLCanvasElement>,
		public callbacks: ObjectSegmentationCallbacks,
		public options: ObjectSegmentationOptions
	) {}
}

class PropsFromParent {
	canvasRef: React.RefObject<HTMLCanvasElement>;
	video: any;
	dimensions: { width: number; height: number };
	callbacks: ObjectSegmentationCallbacks;
	options: ObjectSegmentationOptions;
}

const ObjectSegmentation: React.FC<PropsFromParent> = ({
	canvasRef,
	video,
	dimensions,
	callbacks,
	options,
}) => {
	const { settingsController } = useContext(AppContext);
	const getLibraryController = (library: string) => {
		switch (library) {
			case ObjectSegmentationLibraries.MEDIAPIPE:
				return _MEDIAPIPE_LIBRARY_CONTROLLER;
			case ObjectSegmentationLibraries.BODY_PIX:
				return _BODYPIX_LIBRARY_CONTROLLER;
		}
	};
	const library =
		settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
			SettingPageHeaders.IMAGE_RECOGNITION
		].children[SettingTabHeaders.OBJECT_SEGMENTATION].children[SettingSectionHeaders.SEGMENTATION]
			.children[SettingHeaders.OBJECT_SEGMENTATION_LIBRARY].value;
	let libraryController = getLibraryController(library);

	// a ref, because we dont ever change the created instance
	const controller = useRef(
		new libraryController!(video, dimensions, canvasRef, callbacks, options)
	);

	useEffect(() => {
		controller.current.load();
	}, []);

	useEffect(() => {
		return () => {
			controller.current.unload();
		};
	}, []);

	return (
		<canvas
			className="segmentationContainer"
			ref={canvasRef}
			width={dimensions.width}
			height={dimensions.height}
		/>
	);
};

export default ObjectSegmentation;
