import { setParameter } from 'actions/setParam';
import { SET_CONFIRM_AUTO_PARK, SET_DRIVING_MODE_STATUS } from 'actions/types';
import React, { useMemo, useRef, useState, useEffect, useReducer } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { AppRootState } from 'reducers';
import { RobotPrimaryCamera } from 'types';
import Tooltip from '../tooltip';
import './index.scss';

import genericAlertIcon from 'images/alert.svg';
import drivingViewIcon from 'images/driving-view.svg';
import flashImage from 'images/flash.svg';
import zoomInIcon from 'images/zoom-in.svg';
import zoomOutIcon from 'images/zoom-out.svg';

const reduxConnector = connect(
	(state: AppRootState) => ({
		controlDataChannel: state.sessionState.controlDataChannel,
		localVoiceVolume: state.sessionState.localVoiceVolume,
		hideNavOptionsStatus: state.sessionState.hideNavOptionsStatus,
		microphoneLevel: state.hardwareState.settings.microphone.microphoneLevel,
		drivingMode: state.sessionState.drivingMode,
		navCameraHeight: state.sessionState.navCameraHeight,
		autoParkEnabled: state.sessionState.autoParkEnabled,
		confirmAutoPark: state.sessionState.confirmAutoPark,
		isAutoParking: state.sessionState.isAutoParking,
		dockControllerStatus: state.sessionState.dockControllerStatus,
	}),
	{ setParameter }
);

type PropsFromParent = {
	onClickHangUp: () => void;
	localStream: MediaStream | null;
	navCameraRotation: number;
	hasPrimaryVideoStartedPlaying: boolean;
	isNavCameraVertical: boolean;
};

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const NextCameraActionIcons: Record<RobotPrimaryCamera, string> = {
	[RobotPrimaryCamera.WIDE_CAM]: zoomInIcon,
	[RobotPrimaryCamera.ZOOM_CAM]: zoomOutIcon,
};

const RobotFeatures: React.FC<ComponentProps> = ({
	hasPrimaryVideoStartedPlaying,
	controlDataChannel,
	hideNavOptionsStatus,
	drivingMode,
	setParameter,
	autoParkEnabled,
	confirmAutoPark,
	isAutoParking,
	dockControllerStatus,
}: ComponentProps) => {
	const isSessionOpen =
		hasPrimaryVideoStartedPlaying && controlDataChannel && controlDataChannel.readyState === 'open';
	const canAutoPark =
		autoParkEnabled &&
		((dockControllerStatus.stage === 'DETECTING' && dockControllerStatus.state === 'SUCCESS') ||
			(isAutoParking && ['STARTING', 'DOCKING', 'STOPPING'].includes(dockControllerStatus.stage)));

	const handleToggleDrivingMode = () => {
		setParameter('drivingMode', SET_DRIVING_MODE_STATUS, !drivingMode);
	};
	const onAutoParkClick = () => {
		setParameter('confirmAutoPark', SET_CONFIRM_AUTO_PARK, true);
		document.dispatchEvent(new CustomEvent('firstStartAutoPark'));
	};

	const autoParkButtonPopupText = useMemo(() => {
		if (!autoParkEnabled) return 'AutoPark is not available in this Session';

		if (!canAutoPark) return 'Drive to a charging Dock to enable AutoPark';

		return 'AutoPark';
	}, [autoParkEnabled, canAutoPark]);

	const meetingViewTooltipShowing = useRef(false);
	const setMeetingViewTooltipShowing = (value: boolean) =>
		(meetingViewTooltipShowing.current = value);
	const autoparkTooltipShowing = useRef(false);
	const setAutoparkTooltipShowing = (value: boolean) => (autoparkTooltipShowing.current = value);

	const [tooltipWantsToShow, setTooltipWantsToShow] = useState(false);
	useEffect(() => {
		setTooltipWantsToShow(meetingViewTooltipShowing.current || autoparkTooltipShowing.current);
	}, [meetingViewTooltipShowing.current, autoparkTooltipShowing.current]);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	return (
		<div
			className={hideNavOptionsStatus ? 'robotFeaturesContainer' : 'displayNone'}
			style={{ zIndex: tooltipWantsToShow ? 37 : undefined }}
			onMouseMove={() => forceUpdate()}
		>
			<Tooltip
				left
				text={isSessionOpen ? (drivingMode ? 'Meeting View' : 'Driving View') : undefined}
				wantsToShow={setMeetingViewTooltipShowing}
				showAllowed={tooltipWantsToShow}
			>
				<div className="blackCircle" onClick={handleToggleDrivingMode}>
					<img className="iconWrapper" src={drivingViewIcon} alt="" />
				</div>
			</Tooltip>
			<Tooltip
				left
				showOnChange={canAutoPark}
				text={autoParkButtonPopupText}
				wantsToShow={setAutoparkTooltipShowing}
				showAllowed={tooltipWantsToShow}
			>
				<div
					className={`${
						isAutoParking
							? 'greenCircle'
							: `${confirmAutoPark ? 'selectedBlackCircle' : 'blackCircle'}`
					} ${!canAutoPark && 'disabled'}`}
					onClick={onAutoParkClick}
				>
					<img className="iconWrapper" src={flashImage} alt="" />
					{dockControllerStatus.stage === 'DOCKING' && dockControllerStatus.state === 'FAILED' ? (
						<img className="badge" src={genericAlertIcon} alt="" />
					) : null}
				</div>
			</Tooltip>
		</div>
	);
};

export default reduxConnector(RobotFeatures);
