import React, { useRef } from 'react';
import './index.scss';

const active = '#56ae4d';
const inactive = '#8e8c89';
const warning = '#FFC400';
class PropsFromParent {
	onChange: Function;
	value: string;
	icon: string | null;
	onIconClick?: Function;
	id: string;
	minValue?: number;
	maxValue?: number;
	useWarningThreshold?: boolean;
	warningThreshold?: number;
}

const Slider: React.FC<PropsFromParent> = ({
	onChange,
	value,
	id,
	minValue = 0,
	maxValue = 100,
	useWarningThreshold = false,
	warningThreshold = 66,
}) => {
	const inputRef = useRef<any>(null);

	const handleChange = (min: any, max: any) => (event: any) => {
		onChange(event.target.value);
		const value = event.target.value;
		const progress = (value / max) * 100;
		const newBackgroundStyle = `linear-gradient(90deg, ${active} 0% ${progress}%,   ${inactive} ${progress}% 100%)`;
		inputRef.current.style.background = newBackgroundStyle;

		if (useWarningThreshold) {
			if (progress > warningThreshold) {
				inputRef.current.style.setProperty('--thumb-background-color', warning);
			} else {
				inputRef.current.style.setProperty('--thumb-background-color', active);
			}
		}
	};

	const progress = ((parseInt(value) - minValue) / (maxValue - minValue)) * 100;

	let styleInput;
	if (useWarningThreshold) {
		styleInput = {
			background: `linear-gradient(90deg, ${
				progress > warningThreshold ? warning : active
			} 0% ${progress}%,   ${inactive} ${progress}% ${warningThreshold}%,   ${warning} ${progress}% ${100}%)`,
		};

		if (inputRef.current) {
			if (progress > warningThreshold) {
				inputRef.current.style.setProperty('--thumb-background-color', warning);
			} else {
				inputRef.current.style.setProperty('--thumb-background-color', active);
			}
		}
	} else {
		styleInput = {
			background: `linear-gradient(90deg, ${active} 0% ${progress}%,   ${inactive} ${progress}% 100%)`,
		};
	}

	const onWheelChange = (deltaY: number) => {
		if (deltaY < 0) {
			if (parseInt(value) > 90) {
				onChange(100);
			} else {
				onChange(parseInt(value) + 10);
			}
		} else {
			if (parseInt(value) < 10) {
				onChange(0);
			} else {
				onChange(parseInt(value) - 10);
			}
		}
	};

	return (
		<div className="sliderContainer" id={id}>
			<input
				ref={inputRef}
				id="sliderId"
				className="inputR"
				name="sliderName"
				type="range"
				min={minValue}
				max={maxValue}
				value={value}
				onChange={handleChange(minValue, maxValue)}
				style={styleInput}
				onWheel={(event) => onWheelChange(event.deltaY)}
				onKeyDown={(event) => {
					event.preventDefault();
				}}
				onKeyUp={(event) => {
					event.preventDefault();
				}}
			/>
		</div>
	);
};

export default Slider;
