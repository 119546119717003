/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import Battery from '../battery';
import './index.scss';
import { MAX_STATS_COUNT } from 'GoBeWebRTC/hooks/useStats';
import SessionID from 'components/sessionID';

import highWifiSignalIconHl from 'images/wifi-hl.svg';
import badWiFiSignalIconHl from 'images/wifi-signal-bad-hl.svg';
import badWiFiSignalIcon from 'images/wifi-signal-bad.svg';
import lowWiFiSignalIconHl from 'images/wifi-signal-low-hl.svg';
import lowWiFiSignalIcon from 'images/wifi-signal-low.svg';
import mediumWiFiSignalIconHl from 'images/wifi-signal-medium-hl.svg';
import mediumWiFiSignalIcon from 'images/wifi-signal-medium.svg';
import highWifiSignalIcon from 'images/wifi.svg';

import {
	RobotStatus,
	ConnectionsQuality,
	SessionState,
	Stats,
	RobotPrimaryCamera,
	PilotPrimaryCamera,
	RobotNavCamera,
} from 'GoBeWebRTC/types';
import goBeHeadIcon from 'images/GoBe-head.svg';
import removeIcon from 'images/remove.svg';

import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';

type ComponentProps = {
	robotStatus: RobotStatus;
	connectionsQuality: ConnectionsQuality;
	isLocalVideoExpanded: boolean;
	onToggleLocalVideoExpansion: () => void;
	sessionState: SessionState;
	stats: Stats;
	sessionId: string;
};

type StatSparkLineProps = {
	data: Array<any>;
};

const defaultConnectionsQuality = {
	1: {
		dbm: 0,
		quality: 0,
	},
	2: {
		dbm: 0,
		quality: 0,
	},
};

/**
 * Function used to convert circular buffer into an array
 * @param buffer
 * @param currPointer
 * @param isFilled
 * @returns computedArray
 */
const circularBufferToArray = (buffer: Array<any>, currPointer: number, isFilled: boolean) => {
	return isFilled
		? [...buffer.slice(currPointer + 1), ...buffer.slice(0, currPointer + 1)]
		: buffer.slice(0, currPointer + 1);
};

const StatSparkLine: React.FC<StatSparkLineProps> = ({ data }) => {
	return (
		<Sparklines data={data} limit={30} width={100} height={15}>
			<SparklinesLine style={{ stroke: '#56AE4D', strokeWidth: '2', fill: 'none' }} />
			<SparklinesSpots
				spotColors={{
					'-1': '#ffffff',
					'0': '#ffffff',
					'1': '#ffffff',
				}}
			/>
		</Sparklines>
	);
};

const LocalVideoHeader: React.FC<ComponentProps> = ({
	robotStatus,
	connectionsQuality,
	isLocalVideoExpanded,
	onToggleLocalVideoExpansion,
	sessionState,
	stats,
	sessionId,
}) => {
	const isSessionSoftRetrying: boolean = sessionState === 'SoftRetrying';
	const [wifiIcon, setWifiIcon] = useState<string>(highWifiSignalIcon);
	const [wifiIconLoopId, setWifiIconLoopId] = useState<ReturnType<typeof setInterval>>();
	const [showWifiTooltip, setShowWifiTooltip] = useState<boolean>(false);
	const [showBatteryPercentTooltip, setShowBatteryPercentTooltip] = useState<boolean>(false);
	const [wifiTooltipPinned, setWifiTooltipPinned] = useState<boolean>(false);

	function toggleWifiTooltipPinned() {
		setWifiTooltipPinned((prev) => !prev);
	}

	const interfaceId = useMemo(() => {
		return robotStatus?.interface?.interface_id;
	}, [robotStatus?.interface?.interface_id]);

	const networkMacAddress = useMemo(() => {
		return robotStatus?.network?.bssid;
	}, [robotStatus?.network?.bssid]);

	const connectionsQualityRef = useRef<ConnectionsQuality>(
		connectionsQuality || defaultConnectionsQuality
	);
	useEffect(() => {
		connectionsQualityRef.current = connectionsQuality || defaultConnectionsQuality;
	}, [connectionsQuality]);

	useEffect(() => {
		const wifiIcons = [
			badWiFiSignalIcon,
			lowWiFiSignalIcon,
			mediumWiFiSignalIcon,
			highWifiSignalIcon,
		];
		if (isSessionSoftRetrying) {
			if (!wifiIconLoopId) {
				let index: number = 0;
				clearInterval(wifiIconLoopId!);
				setWifiIconLoopId(
					setInterval(() => {
						setWifiIcon(wifiIcons[index]);
						index = (index + 1) % wifiIcons.length;
					}, 300)
				);
			}
		} else {
			clearInterval(wifiIconLoopId!);
			setWifiIconLoopId(undefined);
		}
		return () => {
			clearInterval(wifiIconLoopId!);
			setWifiIconLoopId(undefined);
		};
	}, [isSessionSoftRetrying]);

	useEffect(() => {
		let icon = highWifiSignalIcon;
		if (robotStatus.network) {
			const quality = robotStatus.network.quality;
			switch (true) {
				case quality >= 80:
					icon = showWifiTooltip ? highWifiSignalIconHl : highWifiSignalIcon;
					break;
				case quality < 80 && quality >= 66:
					icon = showWifiTooltip ? mediumWiFiSignalIconHl : mediumWiFiSignalIcon;
					break;

				case quality < 66 && quality >= 56:
					icon = showWifiTooltip ? lowWiFiSignalIconHl : lowWiFiSignalIcon;
					break;

				case quality < 56:
					icon = showWifiTooltip ? badWiFiSignalIconHl : badWiFiSignalIcon;
					break;
			}
		}
		setWifiIcon(icon);
	}, [robotStatus.network, showWifiTooltip]);

	const [flash, setFlash] = useState(false);
	const currentConnection = isSessionSoftRetrying
		? 'Reconnecting'
		: interfaceId
		? interfaceId.includes('LTE')
			? 'LTE'
			: `WiFi${interfaceId ? `-${interfaceId}` : ''}`
		: '';
	useEffect(() => {
		setFlash(true);
		const timer = setTimeout(() => setFlash(false), 1000); // Remove the flash class after 1 second
		return () => clearTimeout(timer); // Clear the timeout if the component unmounts
	}, [currentConnection]);

	const signalStrengthBufferRef = useRef<string[][]>(
		Array.from({ length: 2 }, () => Array(MAX_STATS_COUNT).fill('0'))
	);
	const bufferIndex = useRef([-1, -1]);
	const isFilled = useRef([false, false]);

	// Enforce updates at a regular interval
	const [tick, setTick] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setTick((tick) => tick + 1);
		}, 100);

		const signalStrengthReaderInterval = setInterval(() => {
			bufferIndex.current[0]++;
			if (bufferIndex.current[0] > MAX_STATS_COUNT) {
				bufferIndex.current[0] = 0;
				isFilled.current[0] = true;
			}
			bufferIndex.current[1]++;
			if (bufferIndex.current[1] > MAX_STATS_COUNT) {
				bufferIndex.current[1] = 0;
				isFilled.current[1] = true;
			}

			signalStrengthBufferRef.current[0][bufferIndex.current[0]] =
				connectionsQualityRef.current[1].quality.toFixed(0);
			signalStrengthBufferRef.current[1][bufferIndex.current[1]] =
				connectionsQualityRef.current[2].quality.toFixed(0);
		}, 1000);

		return () => {
			clearInterval(interval);
			clearInterval(signalStrengthReaderInterval);
		};
	}, []);

	return (
		<div
			className={
				isLocalVideoExpanded
					? ' wifiBatteryContainer'
					: 'miniWifiBatteryContainer wifiBatteryContainer'
			}
		>
			<img
				onClick={onToggleLocalVideoExpansion}
				className="removeIconWrapper"
				src={isLocalVideoExpanded ? removeIcon : goBeHeadIcon}
			/>
			<div
				className="wifiIconWrapper"
				onMouseEnter={() => setShowWifiTooltip(true)}
				onMouseLeave={() => setShowWifiTooltip(false)}
				onClick={toggleWifiTooltipPinned}
			>
				<div className="wifiIcon">
					{showWifiTooltip || wifiTooltipPinned ? (
						<div className="wifiTooltip">
							<div className="tooltipSection">
								<div className="leftColumn">Session ID</div>
								<div className="rightColumn">
									<SessionID id={sessionId} />
								</div>
							</div>
							<div className="divider" />
							<div className="tooltipSection">
								<div className="leftColumn">Connected via</div>
								<div className={`rightColumn ${flash ? 'flash' : ''}`}>
									{currentConnection} {networkMacAddress ? `(${networkMacAddress})` : null}
								</div>
							</div>
							{stats?.succeededCandidatePair?.remote?.candidateType ? (
								<div className="tooltipSection">
									<div className="leftColumn">Robot</div>
									<div className="rightColumn">
										{`${stats?.succeededCandidatePair?.remote?.candidateType}/${stats?.succeededCandidatePair?.remote?.protocol}`}
									</div>
								</div>
							) : null}
							{stats?.succeededCandidatePair?.local?.candidateType ? (
								<div className="tooltipSection">
									<div className="leftColumn">Pilot</div>
									<div className="rightColumn">
										{`${stats?.succeededCandidatePair?.local?.candidateType}/${
											stats?.succeededCandidatePair?.local?.relayProtocol ??
											stats?.succeededCandidatePair?.local?.protocol
										}`}
									</div>
								</div>
							) : null}
							{!interfaceId!.includes('LTE') && (
								<>
									<div className="tooltipSection">
										<div className="leftColumn">Wi-Fi 1 signal strength</div>
										<div className="rightColumn">
											<div className="sparklineContainer">
												<StatSparkLine
													data={circularBufferToArray(
														signalStrengthBufferRef.current[0],
														bufferIndex.current[0],
														isFilled.current[0]
													)}
												/>
											</div>
											<div className="numberHighlight">
												{`${connectionsQualityRef.current[1].quality || 0}`}
											</div>
											% (dBm: {`${connectionsQualityRef.current[1].dbm || 0}`})
										</div>
									</div>
									<div className="tooltipSection">
										<div className="leftColumn">Wi-Fi 2 signal strength</div>
										<div className="rightColumn">
											<div className="sparklineContainer">
												<StatSparkLine
													data={circularBufferToArray(
														signalStrengthBufferRef.current[1],
														bufferIndex.current[1],
														isFilled.current[1]
													)}
												/>
											</div>
											<div className="numberHighlight">
												{`${connectionsQualityRef.current[2].quality || 0}`}
											</div>
											% (dBm: {`${connectionsQualityRef.current[2].dbm || 0}`})
										</div>
									</div>
								</>
							)}

							<div className="divider" />

							<div className="tooltipSection">
								<div className="leftColumn">Framerate wide camera</div>
								<div className="rightColumn">
									<div className="sparklineContainer">
										<StatSparkLine
											data={circularBufferToArray(
												stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.fps.data,
												stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.currPointer,
												stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.isFilled
											)}
										/>
									</div>
									<div className="numberHighlight">
										{`${
											stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.fps.data[
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.currPointer
											]
										}`}
									</div>
									fps
								</div>
							</div>
							<div className="tooltipSection">
								<div className="leftColumn">Framerate nav camera</div>
								<div className="rightColumn">
									<div className="sparklineContainer">
										<StatSparkLine
											data={circularBufferToArray(
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.fps.data,
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.currPointer,
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.isFilled
											)}
										/>
									</div>
									<div className="numberHighlight">
										{`${
											stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.fps.data[
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.currPointer
											]
										}`}
									</div>
									fps
								</div>
							</div>

							<div className="tooltipSection">
								<div className="leftColumn">Bitrate wide camera</div>
								<div className="rightColumn">
									<div className="sparklineContainer">
										<StatSparkLine
											data={circularBufferToArray(
												stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.bitRate.data,
												stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.currPointer,
												stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.isFilled
											)}
										/>
									</div>
									<div className="numberHighlight">
										{`${
											stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.bitRate.data[
												stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.currPointer
											]
										}`}
									</div>
									Kbps
								</div>
							</div>

							<div className="tooltipSection">
								<div className="leftColumn">Bitrate nav camera</div>
								<div className="rightColumn">
									<div className="sparklineContainer">
										<StatSparkLine
											data={circularBufferToArray(
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.bitRate.data,
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.currPointer,
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.isFilled
											)}
										/>
									</div>
									<div className="numberHighlight">
										{`${
											stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.bitRate.data[
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.currPointer
											]
										}`}
									</div>
									Kbps
								</div>
							</div>

							<div className="tooltipSection">
								<div className="leftColumn">Packet loss wide camera</div>
								<div className="rightColumn">
									<div className="sparklineContainer">
										<StatSparkLine
											data={circularBufferToArray(
												stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.packetLoss.data,
												stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.currPointer,
												stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.isFilled
											)}
										/>
									</div>
									<div className="numberHighlight">{`${
										stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.packetLossPercent.data[
											stats?.inboundRTP?.[RobotPrimaryCamera.WIDE_CAM]?.currPointer
										]
									}`}</div>
									%
								</div>
							</div>

							<div className="tooltipSection">
								<div className="leftColumn">Packet loss nav camera</div>
								<div className="rightColumn">
									<div className="sparklineContainer">
										<StatSparkLine
											data={circularBufferToArray(
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.packetLoss.data,
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.currPointer,
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.isFilled
											)}
										/>
									</div>
									<div className="numberHighlight">
										{`${
											stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.packetLossPercent.data[
												stats?.inboundRTP?.[RobotNavCamera.NAV_CAM]?.currPointer
											]
										}`}
									</div>
									%
								</div>
							</div>

							<div className="divider" />

							<div className="tooltipSection">
								<div className="leftColumn">Framerate pilot camera</div>
								<div className="rightColumn">
									<div className="sparklineContainer">
										<StatSparkLine
											data={circularBufferToArray(
												stats?.outboundRTP?.[PilotPrimaryCamera.LOCAL]?.fps.data,
												stats?.outboundRTP?.[PilotPrimaryCamera.LOCAL]?.currPointer,
												stats?.outboundRTP?.[PilotPrimaryCamera.LOCAL]?.isFilled
											)}
										/>
									</div>
									<div className="numberHighlight">
										{`${
											stats?.outboundRTP?.[PilotPrimaryCamera.LOCAL]?.fps.data[
												stats?.outboundRTP?.[PilotPrimaryCamera.LOCAL]?.currPointer
											]
										}`}
									</div>
									fps
								</div>
							</div>

							<div className="tooltipSection">
								<div className="leftColumn">Bitrate pilot camera</div>
								<div className="rightColumn">
									<div className="sparklineContainer">
										<StatSparkLine
											data={circularBufferToArray(
												stats?.outboundRTP?.[PilotPrimaryCamera.LOCAL]?.bitRate.data,
												stats?.outboundRTP?.[PilotPrimaryCamera.LOCAL]?.currPointer,
												stats?.outboundRTP?.[PilotPrimaryCamera.LOCAL]?.isFilled
											)}
										/>
									</div>
									<div className="numberHighlight">
										{`${
											stats?.outboundRTP?.[PilotPrimaryCamera.LOCAL]?.bitRate.data[
												stats?.outboundRTP?.[PilotPrimaryCamera.LOCAL]?.currPointer
											]
										}`}
									</div>
									Kbps
								</div>
							</div>

							<div className="tooltipSection">
								<div className="leftColumn">Bitrate pilot microphone</div>
								<div className="rightColumn">
									<div className="sparklineContainer">
										<StatSparkLine
											data={circularBufferToArray(
												stats?.outboundRTP?.audio?.bitRate.data,
												stats?.outboundRTP?.audio?.currPointer,
												stats?.outboundRTP?.audio?.isFilled
											)}
										/>
									</div>
									<div className="numberHighlight">
										{`${
											stats?.outboundRTP?.audio?.bitRate.data[
												stats?.outboundRTP?.audio?.currPointer
											]
										}`}
									</div>
									Kbps
								</div>
							</div>
							<span className="wifiTooltipArrow" />
						</div>
					) : null}
					<img className="wifiIconImg" src={wifiIcon} />
					{!isSessionSoftRetrying ? <div className="wifiIconId">{interfaceId}</div> : null}
				</div>
			</div>
			<div
				className="batteryIconContainer"
				onMouseEnter={() => setShowBatteryPercentTooltip(true)}
				onMouseLeave={() => setShowBatteryPercentTooltip(false)}
			>
				<div className="batteryIcon">
					{showBatteryPercentTooltip ? (
						<div className="batteryTooltip">
							<span>{robotStatus.battery.level || 0}%</span>
							<span className="batteryTooltipArrow" />
						</div>
					) : null}
					<Battery
						batteryPercent={robotStatus.battery.level}
						charging={robotStatus.battery.charging}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(LocalVideoHeader);
