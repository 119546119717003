import {
	SET_AUTO_PARK_ENABLED,
	SET_BATTERY_STATUS,
	SET_CAMERA_CALIBRATION_DEVIATION_STEP,
	SET_CAMERA_CALIBRATION_TOOL_ENABLED,
	SET_CONFIRM_AUTO_PARK,
	SET_DATA_CHANNEL,
	SET_DOCK_CONTROLLER_STATUS,
	SET_DRAG_MODE,
	SET_DRIVING_MODE_STATUS,
	SET_FULL_SCREEN_STATUS,
	SET_HIDE_NAV_OPTIONS_STATUS,
	SET_iS_ABRUPTLY_CLOSING,
	SET_IS_AUTO_PARKING,
	SET_IS_NAVIGATING,
	SET_MINIMIZATION_IMPAIRED_DRIVING,
	SET_NAV_CAMERA_HEIGHT,
	SET_NAV_SPEED,
	SET_REMOTE_VIDEO_AURA_STYLE,
	SET_REMOTE_VOICE_VOLUME,
	SET_ROBOT_INFO,
	SET_ROBOT_STATUS,
} from 'actions/types';
import { RobotStatus } from 'GoBeWebRTC/types';
import { CSSProperties } from 'react';
import { DragMode, DragModes } from 'types';

export const DEFAULT_NAV_CAMERA_HEIGHT = 250;

type SessionStateModel = {
	sessionStartDate: Date;
	controlDataChannel: any;
	batteryStatus: boolean;
	localVoiceStatus: boolean;
	fullScreenStatus: boolean;
	localVoiceVolume: string;
	remoteVoiceVolume: string;
	navSpeed: string;
	hideNavOptionsStatus: boolean;
	drivingMode: boolean;
	navCameraHeight: number;
	robot: { id: string; name: string; serialNumber: string };
	autoParkEnabled: boolean;
	confirmAutoPark: boolean;
	isAutoParking: boolean;
	dockControllerStatus: any;
	ImpairedDrivingIndicatormin: boolean;
	robotStatus: RobotStatus | null;
	cameraCalibrationToolEnabled: boolean;
	cameraCalibrationDeviationStep: { x: number; y: number };
	dragMode: DragMode;
	getMediaDominantColorTick: number;
	remoteVideoAuraStyle: CSSProperties | null;
	isNavigating: boolean;
	isAbruptlyClosing: boolean;
};

type SessionActionsModel = {
	type: string;
	payload: SessionStateModel;
};

const Initial_State: SessionStateModel = {
	sessionStartDate: new Date(),
	controlDataChannel: null,
	batteryStatus: false,
	localVoiceStatus: false,
	fullScreenStatus: false,
	localVoiceVolume: '25',
	remoteVoiceVolume: '50',
	navSpeed: '50',
	hideNavOptionsStatus: true,
	drivingMode: true,
	navCameraHeight: DEFAULT_NAV_CAMERA_HEIGHT,
	robot: { id: '', name: '', serialNumber: '' },
	autoParkEnabled: false,
	confirmAutoPark: false,
	isAutoParking: false,
	dockControllerStatus: {},
	ImpairedDrivingIndicatormin: false,
	robotStatus: null,
	cameraCalibrationToolEnabled: false,
	cameraCalibrationDeviationStep: { x: 0.1, y: 0.1 },
	dragMode: DragModes.DISABLED,
	getMediaDominantColorTick: 3000,
	remoteVideoAuraStyle: null,
	isNavigating: false,
	isAbruptlyClosing: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = Initial_State, action: SessionActionsModel) => {
	switch (action.type) {
		case SET_DRIVING_MODE_STATUS:
			return { ...state, drivingMode: action.payload.drivingMode };
		case SET_NAV_CAMERA_HEIGHT:
			return { ...state, navCameraHeight: action.payload.navCameraHeight };
		case SET_DATA_CHANNEL:
			return { ...state, controlDataChannel: action.payload.controlDataChannel };
		case SET_BATTERY_STATUS:
			return { ...state, batteryStatus: action.payload.batteryStatus };
		case SET_FULL_SCREEN_STATUS:
			return { ...state, fullScreenStatus: action.payload.fullScreenStatus };
		case SET_HIDE_NAV_OPTIONS_STATUS:
			return {
				...state,
				hideNavOptionsStatus: action.payload.hideNavOptionsStatus,
			};
		case SET_REMOTE_VOICE_VOLUME:
			return { ...state, remoteVoiceVolume: action.payload.remoteVoiceVolume };
		case SET_NAV_SPEED:
			return { ...state, navSpeed: action.payload.navSpeed };
		case SET_ROBOT_INFO:
			return { ...state, robot: action.payload.robot };
		case SET_AUTO_PARK_ENABLED:
			return { ...state, autoParkEnabled: action.payload.autoParkEnabled };
		case SET_CONFIRM_AUTO_PARK:
			return { ...state, confirmAutoPark: action.payload.confirmAutoPark };
		case SET_IS_AUTO_PARKING:
			return { ...state, isAutoParking: action.payload.isAutoParking };
		case SET_DOCK_CONTROLLER_STATUS:
			return { ...state, dockControllerStatus: action.payload.dockControllerStatus };
		case SET_MINIMIZATION_IMPAIRED_DRIVING:
			return { ...state, ImpairedDrivingIndicatormin: action.payload.ImpairedDrivingIndicatormin };
		case SET_ROBOT_STATUS:
			return { ...state, robotStatus: action.payload.robotStatus };
		case SET_CAMERA_CALIBRATION_TOOL_ENABLED:
			return {
				...state,
				cameraCalibrationToolEnabled: action.payload.cameraCalibrationToolEnabled,
			};
		case SET_CAMERA_CALIBRATION_DEVIATION_STEP:
			return {
				...state,
				cameraCalibrationDeviationStep: action.payload.cameraCalibrationDeviationStep,
			};
		case SET_DRAG_MODE:
			return {
				...state,
				dragMode: action.payload.dragMode,
			};
		case SET_REMOTE_VIDEO_AURA_STYLE:
			return {
				...state,
				remoteVideoAuraStyle: action.payload.remoteVideoAuraStyle,
			};
		case SET_IS_NAVIGATING:
			return {
				...state,
				isNavigating: action.payload.isNavigating,
			};
		case SET_iS_ABRUPTLY_CLOSING:
			return {
				...state,
				isAbruptlyClosing: action.payload.isAbruptlyClosing,
			};
		default:
			return state;
	}
};
