import React from 'react';
import './index.scss';
import { LocalSessionInfo } from 'types';

type Props = {
	isVisible: boolean;
	isSessionStarting: boolean;
	isSessionInitializing: boolean;
	robot: LocalSessionInfo['robot'];
	onClickEndSession: () => void;
};

export default function RetryingSessionOverlay({
	onClickEndSession,
	isVisible,
	isSessionStarting,
	isSessionInitializing,
	robot,
}: Props) {
	return isVisible ? (
		<div
			className={`retrying-session-container ${
				isSessionInitializing ? 'retrying-session-container-initializing' : ''
			}`}
		>
			{/* <div className="loading-indicator" /> */}

			<div className="title">Reconnecting...</div>
			<div className={`message ${!isSessionInitializing && isSessionStarting ? 'hidden' : ''}`}>
				Your session will be automatically resumed
			</div>
			<div className="end-session-button" onClick={() => onClickEndSession()}>
				End session
			</div>
		</div>
	) : null;
}
