import { AppContext } from 'context/appContext';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Size } from 'types';
import NavLineConfig, { emitConfigUpdate } from '../../../../utils/aframe/navigationLine';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';

function minMax(_number: number, opts: { min: number; max: number }): number {
	return Math.min(opts.max, Math.max(opts.min, _number));
}

class PropsFromParent {
	size: Size;
	handleHovered: Function;
	handleEnabled: Function;
	className?: string;
}

const JoystickContainer: React.FC<PropsFromParent> = ({
	size,
	handleEnabled,
	handleHovered,
	className,
}) => {
	const [isEnabled, setIsEnabled] = useState<boolean>(false);
	useEffect(() => {
		handleEnabled(isEnabled);
	}, [isEnabled]);

	const { navController, settingsController } = useContext(AppContext);
	const [linear, setLinear] = useState<number>(0);
	const [angular, setAngular] = useState<number>(0);

	enum InputMode {
		NotMouse = 'notMouse',
		Mouse = 'mouse',
	}
	const activeInputMode = useRef<InputMode>(InputMode.NotMouse);
	const showMouseNavLines = useRef<boolean>(false);

	const mouseDownHandler = () => {
		activeInputMode.current = InputMode.Mouse;
		keysDown.current = 0; // Take control from keyboard
		showMouseNavLines.current = true;
	};
	const mouseUpHandler = () => {
		activeInputMode.current = InputMode.NotMouse;
		showMouseNavLines.current = true;
		setLinear(0);
		setAngular(0);
		setIsEnabled(false);
	};
	const enterNavWindowHandler = () => {
		showMouseNavLines.current = true;
		setIsEnabled(true);
	};
	const leaveNavWindowHandler = () => {
		if (activeInputMode.current === InputMode.Mouse) {
			setLinear(1);
		} else {
			showMouseNavLines.current = false;
			setIsEnabled(false);
		}
	};
	const leavePilotBrowserHandler = () => {
		mouseUpHandler();
		showMouseNavLines.current = false;
		setIsEnabled(false);
	};

	const keysDown = useRef(0);
	const lastKey = useRef('');
	const keyUpHandler = (e: KeyboardEvent) => {
		showMouseNavLines.current = true;
		keysDown.current--;
		if (keysDown.current < 0) {
			keysDown.current = 0;
		}
		lastKey.current = '';
	};

	const keyDownHandler = (e: KeyboardEvent) => {
		if (e.key === lastKey.current) {
			return;
		}
		activeInputMode.current = InputMode.NotMouse;
		showMouseNavLines.current = false;
		setLinear(0);
		setAngular(0);
		setIsEnabled(false);
		keysDown.current++;
		lastKey.current = e.key;
	};

	useEffect(() => {
		// For safety reason, commands must be sent continuously
		let interval: ReturnType<typeof setInterval>;
		interval = setInterval(function () {
			navController.onNavCommand({
				linear,
				angular,
			});
			if (angular === 0 && linear === 0) {
				clearInterval(interval);
			}
		}, 100);
		return () => clearInterval(interval);
	}, [linear, angular]);

	useEffect(() => {
		NavLineConfig.invertBackwardSteering =
			settingsController.settings['App settings'].children[
				SettingPageHeaders.AUGMENTED_REALITY
			].children[SettingTabHeaders.GENERAL].children[
				SettingSectionHeaders.AUGMENTED_REALITY
			].children[SettingHeaders.AR_INVERT_BACKWARD_NAV_LINES].value;
	}, [settingsController.settings]);

	const rotPointRef = useRef<HTMLDivElement | null>(null);
	const navBoxRef = useRef<HTMLDivElement | null>(null);
	const lastCallRef = useRef<number>(0);
	useEffect(() => {
		const handleMouseMove = (e: MouseEvent) => {
			// If component is rendered
			if (rotPointRef.current && navBoxRef.current && keysDown.current === 0) {
				const rect = rotPointRef.current.getBoundingClientRect();
				const rotX = rect.left + window.scrollX;
				const rotY = rect.top + window.scrollY;

				// Get angle between cursor and rotation point
				const opposite = e.clientX - rotX;
				const adjacent = e.clientY - rotY;
				let angle = Math.atan(opposite / adjacent);
				// Quadrant 3 and 4 must be adjusted
				if (adjacent > 0 && !NavLineConfig.invertBackwardSteering) {
					angle = angle * -1;
				}

				// Map angle to % angular throttle
				const angularThrottle = minMax(angle / (Math.PI / 2), { min: -1.0, max: +1.0 });

				// Maximum possible linear throttle input
				const navRect = navBoxRef.current.getBoundingClientRect();
				// Shortest distance from rotation point to nav window border
				const maxLinearThrottle = Math.abs(navRect.bottom - rotY);

				// Map distance to % linear throttle
				const linearThrottle = minMax((rotY - e.clientY) / maxLinearThrottle, {
					min: -1.0,
					max: +1.0,
				});

				let navlineLinThrottle = linearThrottle;
				let navlineLinVelocity =
					linearThrottle *
					(linearThrottle > 0
						? NavLineConfig.MAX_LIN_FORWARD_VEL
						: NavLineConfig.MAX_LIN_BACKWARD_VEL);
				let navlineAngVelocity = angularThrottle * NavLineConfig.MAX_ANG_VEL;
				if (showMouseNavLines.current === false) {
					navlineLinThrottle = 0;
					navlineLinVelocity = 0;
					navlineAngVelocity = 0;
				}
				NavLineConfig.linearThrottle = navlineLinThrottle;
				NavLineConfig.linearVelocity = navlineLinVelocity;
				NavLineConfig.angularVelocity = navlineAngVelocity;
				emitConfigUpdate();

				if (activeInputMode.current !== InputMode.Mouse) {
					return;
				}
				// Limit state updates to 10 per second
				const now = Date.now();
				const throttleTime = 100;
				if (now - lastCallRef.current < throttleTime) {
					return;
				}
				lastCallRef.current = now;
				setAngular(angularThrottle);
				setLinear(linearThrottle);
			}
		};
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mousedown', handleMouseMove);

		document.addEventListener('mouseup', mouseUpHandler);
		document.addEventListener('mouseleave', leavePilotBrowserHandler);
		document.addEventListener('keyup', keyUpHandler);
		document.addEventListener('keydown', keyDownHandler);
	}, []);

	const containerStyle = {
		position: 'absolute' as 'absolute',
		zIndex: 4,
		...size,
	};

	return (
		<div
			ref={navBoxRef}
			id="joystickContainerId"
			style={containerStyle}
			className={className}
			onMouseDown={mouseDownHandler}
			onMouseEnter={enterNavWindowHandler}
			onMouseLeave={leaveNavWindowHandler}
		>
			<div
				ref={rotPointRef}
				style={{
					width: '1px',
					height: '1px',
					// backgroundColor: 'orange', // Debugging
					position: 'absolute',
					top: '63%',
					left: '50%',
				}}
			/>
		</div>
	);
};

export default JoystickContainer;
