import RobotFeatures from 'components/robotFeatures';
import SessionOptions from 'components/sessionOptions';
import { AppContext } from 'context/appContext';
import useDelayedShowOrHide from 'hooks/useDelayedShowOrHide';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import { ComponentProps, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { connect, ConnectedProps } from 'react-redux';
import { AppRootState, useTypedSelector } from 'reducers';
import NavigationVideo from '../../videos/navigationVideo';
import { VIDEO_SEPARATOR_HEIGHT } from '../../videos/remoteVideo';
import './index.scss';

type NavVideoProps = ComponentProps<typeof NavigationVideo>;
type SessionOptionsProps = ComponentProps<typeof SessionOptions>;
type RobotFeaturesProps = ComponentProps<typeof RobotFeatures>;

const reduxConnector = connect(
	(state: AppRootState) => ({
		remoteVideoAuraStyle: state.sessionState.remoteVideoAuraStyle,
		navCameraHeight: state.sessionState.navCameraHeight,
	}),
	{}
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type Props = { navCameraRotation: number } & { initialVolume?: number } & Omit<
		NavVideoProps,
		'isNavCameraVertical'
	> &
	Omit<SessionOptionsProps, 'isNavCameraVertical'> &
	PropsFromRedux;

const NavViewWithSessionOptions = (props: Props) => {
	const { settingsController } = useContext(AppContext);
	const SHOW_SESSION_OPTION_WHEN_DRIVING = true;

	const [isIdle, setIsIdle] = useState(false);

	const onIdle = useCallback(() => {
		setIsIdle(true);
	}, []);
	const onActive = useCallback(() => {
		setIsIdle(false);
	}, []);
	const idleTimer = useIdleTimer({ onActive, onIdle, timeout: 5 * 1000 });

	const isInDrivingMode = useTypedSelector((state) => state.sessionState.drivingMode);
	useEffect(() => {
		if (isInDrivingMode) {
			idleTimer.pause();
		} else {
			idleTimer.reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInDrivingMode]);

	const isSessionOptionsVisible =
		useDelayedShowOrHide(!useTypedSelector((state) => state.sessionState.isNavigating), {
			showDelayMs: 1000,
			hideDelayMs: 0,
		}) || SHOW_SESSION_OPTION_WHEN_DRIVING;

	const isNavCameraVertical: boolean = useMemo(
		() => Math.abs(props.navCameraRotation) % 180 === 90,
		[props.navCameraRotation]
	);

	// Media dominant color
	const transition = `background-color ${useTypedSelector(
		(state) => state.sessionState.getMediaDominantColorTick
	)}ms ease-in`;

	// Use Skin
	// const skin = useMemo(
	// 	() =>
	// 		settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
	// 			SettingPageHeaders.APPEARANCE
	// 		].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.SKIN].children[
	// 			SettingHeaders.NAV_CAM_SKIN
	// 		].value,
	// 	[settingsController.settings]
	// );

	return (
		<div
			className={
				isIdle
					? 'navViewContainerHidden'
					: isInDrivingMode
					? 'navViewContainer'
					: 'navViewContainerDriving'
			}
			style={{ height: props.navCameraHeight - VIDEO_SEPARATOR_HEIGHT }}
		>
			{/* {isInDrivingMode && skin ? (
				<div
					className={`navViewContainerBackground ${skin ? `pattern${skin.replace(' ', '-')}` : ''}`}
				/>
			) : null} */}
			{isInDrivingMode && props.remoteVideoAuraStyle ? (
				<div
					className="navViewContainerBackground navViewContainerBackgroundAura"
					style={props.remoteVideoAuraStyle as React.CSSProperties}
				/>
			) : null}
			{isSessionOptionsVisible ? (
				<RobotFeatures {...({ ...props, isNavCameraVertical } as RobotFeaturesProps)} />
			) : null}
			<NavigationVideo {...(props as NavVideoProps)} />
			{isSessionOptionsVisible ? (
				<SessionOptions {...({ ...props, isNavCameraVertical } as SessionOptionsProps)} />
			) : null}
		</div>
	);
};

export default reduxConnector(NavViewWithSessionOptions);
