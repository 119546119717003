import { FC } from 'react';
import React, { useState } from 'react';

import './index.scss';
interface snackbar {
	title?: string;
	actionRequired?: React.ReactNode;
	icon?: string;
	iconDisplayRule?: 'Both' | 'Minimized' | 'Regular';
	iconWrapper?: string;
	className?: string;
}
const Snackbar: FC<snackbar> = (props): JSX.Element => {
	const [isMinimized, setIsMinimized] = useState(false);

	let iconDisplayRule = props.iconDisplayRule;
	if (!iconDisplayRule) iconDisplayRule = 'Both';

	return isMinimized ? (
		<div className="statusBarMsgcontainer isMinimized" onClick={() => setIsMinimized(false)}>
			{props.icon && (iconDisplayRule === 'Minimized' || iconDisplayRule === 'Both') ? (
				<img className="navIcon" src={props.icon} alt="" />
			) : null}
		</div>
	) : (
		<div className="statusBarMsgcontainer">
			<div className="statusicontitle">
				<div className="status">
					{props.icon && (iconDisplayRule === 'Regular' || iconDisplayRule === 'Both') ? (
						<div className="statusTitle">{props.title}</div>
					) : null}
				</div>

				<div className="statusAction">{props.actionRequired}</div>
			</div>
			<img
				className="closeIconWrapper"
				onClick={() => setIsMinimized(true)}
				src={props.iconWrapper}
				alt=" "
			/>
		</div>
	);
};
export default Snackbar;
