import { setParameter } from 'actions/setParam';
import { SET_REMOTE_VIDEO_AURA_STYLE } from 'actions/types';
import Draggable from 'components/draggable';
import { MiniMap, TransformComponent, TransformWrapper } from 'components/zoomPanPinch/components';
import { AppContext } from 'context/appContext';
import { PivotState, PrimaryCameraState, RobotPrimaryCamera, SessionState } from 'GoBeWebRTC/types';
import { downloadBlob } from 'GoBeWebRTC/utils';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import { useVideoAura } from 'hooks/useVideoAura';
import zoomInIcon from 'images/add-circle-outline.svg';
import resetIcon from 'images/expand-outline.svg';
import zoomOutIcon from 'images/remove-circle-outline.svg';
import centerIcon from 'images/scan-circle-outline.svg';
import ZoomTransition from 'pages/session/overlays/zoomTransition';
import ObjectDetection from 'pages/session/videos/objectDetection';
import VideoCalibrationOverlay from 'pages/session/videos/VideoCalibrationOverlay';
import React, {
	CSSProperties,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import useDimensions from 'react-cool-dimensions';
import { connect, ConnectedProps } from 'react-redux';
import { Rnd } from 'react-rnd';
import { ReactZoomPanPinchRef, ReactZoomPanPinchState } from 'react-zoom-pan-pinch';
import { AppRootState, useTypedSelector } from 'reducers';
import { CameraConfig } from 'types';
import { sessionSoftRetryingFilter } from 'utils/styling';
import RemoteNavOverlay from './RemoteNavOverlay';
import './index.scss';

const reduxConnector = connect(
	(state: AppRootState) => ({
		dragMode: state.sessionState.dragMode,
	}),
	{ setParameter }
);

type PropsFromParent = {
	className?: string;
	drivingMode: boolean;
	fullScreenStatus: boolean;
	navCameraHeight: number;
	miniViewVideoRef: any;
	videoRef: any;
	resizeNavCamera: (value: number) => void;
	primaryCameraState: PrimaryCameraState;
	pivotState: PivotState;
	cameraConfigs: Record<RobotPrimaryCamera, CameraConfig>;
	cameraConfig: CameraConfig;
	sessionState: SessionState;
	cameraCalibrationToolEnabled: boolean;
	cameraCalibrationDeviationStep: { x: number; y: number };
	isDigitalZoomEnabled: boolean;
	isSuperZoomEnabled: boolean;
	isSuperZoomPivotEnabled: boolean;
	toggleSuperZoom: () => void;
	pivot: (degrees: number) => void;
} & React.VideoHTMLAttributes<HTMLVideoElement>;

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

type MediaStylesOptions = {
	containerWidth: number;
	containerHeight: number;
	crop: { top: number; right: number; bottom: number; left: number };
	cameraRotationDegree: 0 | 90 | 180 | 270;
	videoFrameWidth: number;
	videoFrameHeight: number;
	deviation: { x: number; y: number };
};

const VIDEO_FRAME_WIDTH = 1280;
const VIDEO_FRAME_HEIGHT = 720;
const WIDE_CAM_VIEW_DEGREES = 170;

/** Fraction of the Window's height */
const [MAX_HEIGHT_RATIO, MIN_HEIGHT_RATIO] = [0.75, 0.4];

const computeMediaStyles = ({
	containerWidth,
	containerHeight,
	crop,
	cameraRotationDegree,
	videoFrameWidth,
	videoFrameHeight,
	deviation,
}: MediaStylesOptions) => {
	let [x1, x2] = [
		Math.min(VIDEO_FRAME_WIDTH, Math.max(0, crop.left)),
		VIDEO_FRAME_WIDTH - Math.min(VIDEO_FRAME_WIDTH, Math.max(0, crop.right)),
	];

	if (crop.left + crop.right >= VIDEO_FRAME_WIDTH) {
		[x1, x2] = [0, 0];
	}

	const videoCroppingBoxWidth = x2 - x1;
	const videoCroppingBoxHeight = videoFrameHeight;

	let scaleFactor =
		containerWidth < containerHeight
			? containerWidth / videoCroppingBoxWidth
			: containerHeight / videoCroppingBoxHeight;
	scaleFactor = Number.isFinite(scaleFactor) ? scaleFactor : 1;

	const videoTranslateX = x1 * scaleFactor;
	const videoTranslateY = ((videoFrameHeight - videoCroppingBoxHeight) / 2) * scaleFactor;

	const scaleRatio =
		1 /
		(1 -
			(2 * Math.abs(Math.abs(deviation.x) > Math.abs(deviation.y) ? deviation.x : deviation.y)) /
				100);

	/** CSS style of the div (or other box element) used to crop (hide parts of) the video element. */
	const videoCroppingBoxStyle: React.CSSProperties = {
		width: videoCroppingBoxWidth * scaleFactor,
		height: videoCroppingBoxHeight * scaleFactor,
	};

	/** CSS style of the video element itself */
	const videoStyle: React.CSSProperties = {
		transform: `rotate(${cameraRotationDegree}deg) translateY(${videoTranslateY}px) translateX(${videoTranslateX}px) scale(${scaleRatio})`,
		transformOrigin: 'center',
		...(deviation.x
			? {
					marginRight: `${
						(deviation.x * scaleRatio * Number(videoCroppingBoxStyle.width) * 2) / 100
					}px`,
			  }
			: {}),
		...(deviation.y
			? {
					marginTop: `${
						(deviation.y * scaleRatio * Number(videoCroppingBoxStyle.height) * 2) / 100
					}px`,
			  }
			: {}),
	};

	return Object.freeze({ videoStyle, videoCroppingBoxStyle });
};

type Dimension = {
	height: number;
	minHeight?: number | undefined;
	maxHeight?: number | undefined;
};

function DynamicMedia({
	setParameter,
	// className,
	miniViewVideoRef,
	videoRef,
	drivingMode: isInDrivingMode,
	resizeNavCamera,
	primaryCameraState,
	pivotState,
	cameraConfigs,
	cameraConfig,
	fullScreenStatus: isInFullscreenMode,
	navCameraHeight,
	sessionState,
	cameraCalibrationToolEnabled,
	cameraCalibrationDeviationStep,
	isDigitalZoomEnabled,
	isSuperZoomEnabled,
	isSuperZoomPivotEnabled,
	toggleSuperZoom,
	pivot,
	dragMode,
	...mediaComponentProps
}: ComponentProps) {
	const { settingsController, navController } = useContext(AppContext);
	const primaryCameraStateRef = useRef<PrimaryCameraState>(primaryCameraState);
	const pivotStateRef = useRef<PivotState>(pivotState);
	const [deviationPercentage, setDeviationPercentage] = useState<{ x: number; y: number }>(
		cameraConfig.deviation
	);
	const isSessionSoftRetrying = sessionState === 'SoftRetrying';
	const {
		observe,
		width: observedResizeContainerWidth,
		// height: observedResizeContainerHeight,
	} = useDimensions<HTMLDivElement>();

	const [resizeContainerDimensions, _setResizeContainerDimensions] = useState<Dimension>({
		height: (isInDrivingMode ? MAX_HEIGHT_RATIO : 1) * window.innerHeight,
		minHeight: isInDrivingMode ? MIN_HEIGHT_RATIO * window.innerHeight : undefined,
		maxHeight: isInDrivingMode ? window.innerHeight - 240 : undefined,
	});

	const heightRatio = useRef(resizeContainerDimensions.height / window.innerHeight);

	useMemo(() => {
		setDeviationPercentage(cameraConfig.deviation);
		return cameraConfig.deviation;
	}, [cameraConfig.deviation]);

	useMemo(() => {
		primaryCameraStateRef.current = primaryCameraState;
	}, [primaryCameraState]);

	useMemo(() => {
		pivotStateRef.current = pivotState;
	}, [pivotState]);

	const { videoCroppingBoxStyle, videoStyle } = useMemo(() => {
		let { videoCroppingBoxStyle, videoStyle } = computeMediaStyles({
			cameraRotationDegree: cameraConfig.rotationDegrees,
			containerWidth: observedResizeContainerWidth,
			containerHeight: resizeContainerDimensions.height,
			crop: {
				top: cameraConfig.crop.top,
				right: cameraConfig.crop.right,
				bottom: cameraConfig.crop.bottom,
				left: cameraConfig.crop.left,
			},
			videoFrameHeight: VIDEO_FRAME_HEIGHT,
			videoFrameWidth: VIDEO_FRAME_WIDTH,
			deviation: {
				x: deviationPercentage
					? (Math.abs(cameraConfig.rotationDegrees!) === 180 ? -1 : 1) * deviationPercentage.x
					: 0,
				y: deviationPercentage
					? (Math.abs(cameraConfig.rotationDegrees) === 180 ? -1 : 1) * deviationPercentage.y
					: 0,
			},
		});
		videoStyle = {
			...videoStyle,
			...sessionSoftRetryingFilter(isSessionSoftRetrying),
		};

		return { videoCroppingBoxStyle, videoStyle };
	}, [
		cameraConfig.rotationDegrees,
		cameraConfig.crop.top,
		cameraConfig.crop.right,
		cameraConfig.crop.bottom,
		cameraConfig.crop.left,
		observedResizeContainerWidth,
		resizeContainerDimensions.height,
		deviationPercentage,
		isSessionSoftRetrying,
	]);

	const superZoomWithoutPivotPosition = cameraConfigs[RobotPrimaryCamera.ZOOM_CAM].position!;

	const superZoomWithPivotPosition = {
		deviation_percentage: {
			...superZoomWithoutPivotPosition.deviation_percentage,
			x: 0,
		},
		dimension_percentage: {
			...superZoomWithoutPivotPosition.dimension_percentage,
			width: 1,
		},
	};

	const superZoomPosition = isSuperZoomPivotEnabled
		? superZoomWithPivotPosition
		: superZoomWithoutPivotPosition;

	const miniViewZoomOverlayStyle = {
		position: 'absolute',
		display: 'flex',
		top: `${superZoomPosition.deviation_percentage.y * 100}%`,
		left: `${superZoomPosition.deviation_percentage.x * 100}%`,
		width: `${superZoomPosition.dimension_percentage.width * 100}%`,
		height: `${superZoomPosition.dimension_percentage.height * 100}%`,
		backgroundColor: 'lightGreen',
		opacity: 0.5,
		boxSizing: 'border-box',
		fontFamily: 'Roboto',
		fontWeight: 'bolder',
		fontSize: '9px',
		color: 'white',
		filter: 'drop-shadow(0px 0px 4px black)',
	} as const;

	const updateComponentDimensions = useCallback(
		(dimension: Dimension) => {
			const newHeightRatio = Math.min(1, dimension.height / window.innerHeight);

			if (isInDrivingMode) {
				if (newHeightRatio >= MIN_HEIGHT_RATIO && newHeightRatio <= MAX_HEIGHT_RATIO) {
					heightRatio.current = newHeightRatio;
					_setResizeContainerDimensions((state) => ({
						...state,
						...dimension,
					}));
				}
			} else {
				// LOGIC:
				_setResizeContainerDimensions((state) => ({ ...state, ...dimension }));
				//
				// 1. In meeting mode (the opposite of driving-mode), we dont want to track the height ratio.
				//    We maintain the last known ratio. When the user goes back into driving-mode, we will re-use this last-known ratio
				//      to determine the height of the primary-camera
			}

			resizeNavCamera((1 - newHeightRatio) * window.innerHeight);
		},
		[isInDrivingMode, resizeNavCamera]
	);

	// Update the component's dimensions when we toggle driving mode, or toggle fullscreen or resize the window.
	useEffect(() => {
		const recomputeDimensions = () => {
			if (isInDrivingMode) {
				const minHeight = MIN_HEIGHT_RATIO * window.innerHeight;
				const maxHeight = window.innerHeight - 240;
				let height = window.innerHeight * heightRatio.current;

				height = Math.min(Math.max(minHeight, height), maxHeight);

				updateComponentDimensions({
					height,
					minHeight: minHeight,
					maxHeight: maxHeight,
				});
			} else {
				updateComponentDimensions({
					height: window.innerHeight,
					minHeight: undefined,
					maxHeight: undefined,
				});
			}
		};

		recomputeDimensions();

		window.addEventListener('resize', recomputeDimensions);
		return () => {
			window.removeEventListener('resize', recomputeDimensions);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isInDrivingMode,
		isInFullscreenMode,
		// updateComponentDimensions,
	]);

	const style = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};
	const video = (
		<div className="media-cropping-box" style={videoCroppingBoxStyle}>
			{primaryCameraStateRef.current.isChangingPrimaryCameraTo ||
			pivotStateRef.current.currentPivot ? (
				<ZoomTransition />
			) : null}
			<video style={videoStyle} ref={videoRef} {...mediaComponentProps} />
		</div>
	);
	const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

	// Use object detection
	const objectDetectionEnabled = useMemo(
		() =>
			settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
				SettingPageHeaders.IMAGE_RECOGNITION
			].children[SettingTabHeaders.OBJECT_DETECTION].children[SettingSectionHeaders.DETECTION]
				.children[SettingHeaders.ENABLE_OBJECT_DETECTION].value,
		[settingsController.settings]
	);
	const objectDetectionFaceTrackingEnabled = useMemo(
		() =>
			settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
				SettingPageHeaders.IMAGE_RECOGNITION
			].children[SettingTabHeaders.OBJECT_DETECTION].children[SettingSectionHeaders.DETECTION]
				.children[SettingHeaders.ENABLE_OBJECT_DETECTION_FACE_TRACKING].value,
		[settingsController.settings]
	);

	const objectDetection = useMemo(() => {
		return videoRef?.current && videoCroppingBoxStyle.width && videoCroppingBoxStyle.height ? (
			<ObjectDetection
				video={videoRef?.current}
				dimensions={videoCroppingBoxStyle as any}
				callbacks={{
					zoomTo: (x: number, y: number, scale: number) =>
						transformComponentRef?.current?.setTransform(x, y, scale, 700, 'linear'),
				}}
				options={{
					faceTracking: objectDetectionFaceTrackingEnabled,
				}}
			/>
		) : null;
	}, [
		videoCroppingBoxStyle,
		videoRef,
		transformComponentRef?.current,
		objectDetectionFaceTrackingEnabled,
	]);
	const miniViewVideo = (
		<div className="media-cropping-box" style={videoCroppingBoxStyle}>
			<video
				style={{
					...videoStyle,
					...(primaryCameraStateRef.current.isChangingPrimaryCameraTo ||
					pivotStateRef.current.currentPivot
						? {
								filter: 'blur(50px)',
								backdropFilter: 'blur(50px)',
								animation: 'blurAndBrightenInAndOut 2s infinite',
						  }
						: {}),
				}}
				ref={miniViewVideoRef}
				autoPlay
				playsInline
				loop
				controls
				muted
			/>
		</div>
	);
	const [showMiniView, setShowMiniView] = useState<boolean>(false);
	let hidingMiniViewTimeoutId: ReturnType<typeof setTimeout> | undefined;
	const [digitalZoomEnabled, setDigitalZoomEnabled] = useState<boolean>(isDigitalZoomEnabled);
	const superZoomEnabledRef = useRef<boolean>(isSuperZoomEnabled);
	const zoomDisabled = useMemo(
		() =>
			Boolean(
				!digitalZoomEnabled ||
					(superZoomEnabledRef.current &&
						(primaryCameraStateRef?.current?.isChangingPrimaryCameraTo ||
							pivotStateRef?.current?.currentPivot))
			),
		[
			digitalZoomEnabled,
			primaryCameraStateRef?.current?.isChangingPrimaryCameraTo,
			pivotStateRef?.current?.currentPivot,
		]
	);
	const [wideCamZoomState, setWideCamZoomState] = useState<ReactZoomPanPinchState | null>();
	const [superZoomEscape, setSuperZoomEscape] = useState<{
		timestamp: Date | null;
		count: number;
		sensitivityInMs: number;
		target: number;
	}>({
		timestamp: new Date(),
		count: 0,
		sensitivityInMs: 3000,
		target: 2,
	});
	const escapeSuperZoomSensitivity = 0.5;
	const toggleSuperZoomRef = useRef<() => void>();
	toggleSuperZoomRef.current = toggleSuperZoom;
	const pivotRef = useRef<(degrees: number) => void>();
	pivotRef.current = pivot;
	const resetView = () => {
		transformComponentRef?.current?.centerView(undefined, 0);
		transformComponentRef?.current?.resetTransform(0);
	};

	// Sets the state for escape transition from super zoom
	useMemo(() => {
		if (
			primaryCameraStateRef?.current?.isChangingPrimaryCameraTo === RobotPrimaryCamera.WIDE_CAM &&
			!pivotStateRef.current.currentPivot
		) {
			// To avoid re-toggle use escapeSuperZoomSensitivity
			const scale =
					(videoCroppingBoxStyle.height as number) /
						(superZoomPosition.dimension_percentage.height *
							(videoCroppingBoxStyle.height as number)) -
					escapeSuperZoomSensitivity,
				sensitivityWidth =
					(escapeSuperZoomSensitivity / scale) * (videoCroppingBoxStyle.width as number),
				sensitivityHeight =
					(escapeSuperZoomSensitivity / scale) * (videoCroppingBoxStyle.height as number),
				positionX =
					-1 *
					(superZoomWithoutPivotPosition.deviation_percentage.x *
						(videoCroppingBoxStyle.width as number) *
						scale -
						sensitivityWidth / 2),
				positionY =
					-1 *
					(superZoomPosition.deviation_percentage.y *
						(videoCroppingBoxStyle.height as number) *
						scale -
						sensitivityHeight / 2);

			transformComponentRef?.current?.setTransform(positionX, positionY, scale, 0);
		}

		return {
			to: primaryCameraStateRef?.current?.isChangingPrimaryCameraTo,
			pivot: pivotStateRef.current.currentPivot,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		primaryCameraStateRef?.current?.isChangingPrimaryCameraTo,
		pivotStateRef.current.currentPivot,
	]);
	// Sets the state to un-zoomed if wideCamZoomState is null
	useMemo(() => {
		if (
			primaryCameraStateRef?.current?.currentPrimaryCamera === RobotPrimaryCamera.WIDE_CAM &&
			!pivotStateRef?.current?.currentPivot &&
			!wideCamZoomState
		)
			resetView();
		return {
			cam: primaryCameraStateRef?.current?.currentPrimaryCamera,
			pivot: pivotStateRef?.current?.currentPivot,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [primaryCameraStateRef?.current?.currentPrimaryCamera, pivotStateRef?.current?.currentPivot]);
	// Sets the state for transition to super zoom
	useMemo(() => {
		if (
			primaryCameraStateRef?.current?.currentPrimaryCamera === RobotPrimaryCamera.ZOOM_CAM &&
			!pivotStateRef.current.currentPivot
		) {
			resetView();
		}

		return {
			cam: primaryCameraStateRef?.current?.currentPrimaryCamera,
			pivot: pivotStateRef.current.currentPivot,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [primaryCameraStateRef?.current?.currentPrimaryCamera, pivotStateRef?.current?.currentPivot]);
	const selectedCamera = useMemo(
		() => primaryCameraState.isChangingPrimaryCameraTo ?? primaryCameraState.currentPrimaryCamera,
		[primaryCameraState.currentPrimaryCamera, primaryCameraState.isChangingPrimaryCameraTo]
	);
	const [zoomScale, setZoomScale] = useState<number>(1);
	const currentZoomScale = useMemo(
		() =>
			(selectedCamera === RobotPrimaryCamera.WIDE_CAM ||
			primaryCameraStateRef.current.isChangingPrimaryCameraTo ||
			pivotStateRef.current.currentPivot
				? 0
				: 4) + (zoomScale ?? 0),
		[selectedCamera, zoomScale]
	);

	// // Use Aura
	// const [AuraBackgroundStyle, setAuraBackgroundStyle] = useState<CSSProperties | null>(null);
	// const [backgroundTransitionTick] = useState(
	// 	useTypedSelector((state) => state.sessionState.getMediaDominantColorTick)
	// );
	// const { start: startAura, stop: stopAura } = useVideoAura(
	// 	videoRef,
	// 	(style: CSSProperties | null) => {
	// 		setParameter('remoteVideoAuraStyle', SET_REMOTE_VIDEO_AURA_STYLE, style);
	// 		setAuraBackgroundStyle(style);
	// 	},
	// 	backgroundTransitionTick
	// );
	// useMemo(() => {
	// 	const value =
	// 		settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
	// 			SettingPageHeaders.APPEARANCE
	// 		].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.AURA].children[
	// 			SettingHeaders.SHOW_AURA
	// 		].value;
	// 	if (value) startAura();
	// 	else stopAura();
	// 	return value;
	// }, [settingsController.settings]);

	// Use Skin
	// const skin = useMemo(
	// 	() =>
	// 		settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
	// 			SettingPageHeaders.APPEARANCE
	// 		].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.SKIN].children[
	// 			SettingHeaders.REMOTE_CAM_SKIN
	// 		].value,
	// 	[settingsController.settings]
	// );

	// TODO: remove it
	(window as any).toggleSuperZoom = () => {
		resetView();
		superZoomEnabledRef.current = !superZoomEnabledRef.current;
	};
	(window as any).toggleDigitalZoom = () => {
		resetView();
		setDigitalZoomEnabled(!digitalZoomEnabled);
	};

	// const [enableCam, setEnableCam] = useState(true);
	// useEffect(() => {
	// 	const handleEvent = (event: CustomEvent) => {
	// 		setEnableCam(!enableCam);
	// 		console.log('hit', enableCam);

	// 		if (!enableCam) {
	// 			const scene = document.querySelector('#primary_scene');
	// 			const sceneParent = scene.parentNode;
	// 			console.log('removed');

	// 			// Remove the scene
	// 			//@ts-ignore
	// 			sceneParent.removeChild(scene);
	// 		}
	// 	};

	// 	// Listen for custom event
	// 	window.addEventListener('PrimaryCamLoad', handleEvent as EventListener);

	// 	// Cleanup function
	// 	return () => {
	// 		// Remove event listener when component is unmounted
	// 		window.removeEventListener('PrimaryCamLoad', handleEvent as EventListener);
	// 	};
	// }, []);

	return (
		<Rnd
			className={`dynamic-media ${isInFullscreenMode ? 'dynamic-media-full-screen' : ''}`}
			enableResizing={{
				top: false,
				right: false,
				bottom: isInDrivingMode,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			}}
			disableDragging
			style={style}
			size={{ height: resizeContainerDimensions.height, width: '100%' }}
			position={{ x: 0, y: 0 }}
			minHeight={resizeContainerDimensions.minHeight}
			maxHeight={resizeContainerDimensions.maxHeight}
			onResize={(e, direction, ref, delta, position) => {
				const height = parseInt(ref.style.height, 10);
				updateComponentDimensions({ height });
			}}
		>
			{/* {skin ? (
				<div
					className={`dynamic-media-background ${skin ? `pattern${skin.replace(' ', '-')}` : ''}`}
				/>
			) : null}
			{AuraBackgroundStyle ? (
				<div
					className="dynamic-media-background dynamic-media-backgroundAura"
					style={AuraBackgroundStyle}
				/>
			) : null} */}
			<div className="resize-container-inner" ref={observe}>
				<TransformWrapper
					ref={transformComponentRef}
					alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
					limitToBounds
					centerZoomedOut
					disabled={zoomDisabled}
					maxPositionX={videoCroppingBoxStyle.width as number}
					maxPositionY={videoCroppingBoxStyle.height as number}
					onTransformed={(ref, state) => {
						setZoomScale(Math.floor(ref?.state?.scale!));
						if (!ref) {
							setShowMiniView(false);
							return;
						}
						// Handle mini view display visibility
						if (
							primaryCameraStateRef?.current?.currentPrimaryCamera ===
								RobotPrimaryCamera.WIDE_CAM &&
							!(
								primaryCameraStateRef?.current?.isChangingPrimaryCameraTo ||
								pivotStateRef.current.currentPivot
							)
						)
							if (state.positionX + state.positionY === 0 && state.scale === 1) {
								hidingMiniViewTimeoutId = setTimeout(() => setShowMiniView(false), 400);
							} else {
								clearTimeout(hidingMiniViewTimeoutId!);
								hidingMiniViewTimeoutId = undefined;
								setShowMiniView(true);
							}

						// Handle super zoom toggle
						if (
							superZoomEnabledRef.current &&
							!(
								primaryCameraStateRef?.current?.isChangingPrimaryCameraTo ||
								pivotStateRef.current.currentPivot
							) &&
							primaryCameraStateRef?.current?.currentPrimaryCamera === RobotPrimaryCamera.WIDE_CAM
						) {
							const maxDimensions = {
								width: ref.instance.setup.maxPositionX,
								height: ref.instance.setup.maxPositionY,
							};
							const zoomDimensions = {
								x: Math.abs(state.positionX / state.scale),
								y: Math.abs(state.positionY / state.scale),
								width: maxDimensions.width! / state.scale,
								height: maxDimensions.height! / state.scale,
							};
							const superZoomDimensions = {
								x: superZoomPosition.deviation_percentage.x * maxDimensions.width!,
								y: superZoomPosition.deviation_percentage.y * maxDimensions.height!,
								width: superZoomPosition.dimension_percentage.width * maxDimensions.width!,
								height: superZoomPosition.dimension_percentage.height * maxDimensions.height!,
							};
							const sensitivity = 10;
							if (
								zoomDimensions.x + sensitivity >= superZoomDimensions.x &&
								zoomDimensions.x - sensitivity + zoomDimensions.width <=
									superZoomDimensions.x + superZoomDimensions.width &&
								zoomDimensions.y + sensitivity >= superZoomDimensions.y &&
								zoomDimensions.y - sensitivity + zoomDimensions.height <=
									superZoomDimensions.y + superZoomDimensions.height
							) {
								// Toggle super zoom
								setWideCamZoomState({
									...state,
									previousScale: ref?.state.previousScale,
								} as ReactZoomPanPinchState);
								toggleSuperZoomRef?.current!();
								if (isSuperZoomPivotEnabled) {
									// Send pivot command to place the zoomed in area in the position of zoom camera relative to wide camera
									// direction is in polar coordinates thus -1 *
									const pivotDegrees =
										-1 *
										Math.round(
											((zoomDimensions.x -
												superZoomWithoutPivotPosition.deviation_percentage.x *
													maxDimensions.width!) /
												maxDimensions.width!) *
												WIDE_CAM_VIEW_DEGREES
										);
									pivotRef.current!(pivotDegrees);
									console.log('PIVOT DEGREES', pivotDegrees);
								}
							}
						}
					}}
					onZoomStart={({ state }, event) => {
						if (
							!(
								primaryCameraStateRef.current.isChangingPrimaryCameraTo ||
								pivotStateRef.current.currentPivot
							) &&
							primaryCameraStateRef.current.currentPrimaryCamera === RobotPrimaryCamera.ZOOM_CAM &&
							state.scale === 1
						) {
							let trueEvent = event as WheelEvent;
							// Check if user zoomed out
							if (trueEvent.deltaY > 0) {
								toggleSuperZoomRef?.current!();
							}
						}
					}}
				>
					{(utils) => (
						<>
							<div
								className={`mini-view-container${
									!(digitalZoomEnabled && showMiniView) ? '-hidden' : ''
								}`}
							>
								<Draggable
									mode={dragMode}
									content={
										<div className="mini-view">
											<MiniMap
												width={(videoCroppingBoxStyle.width as number) / 4.5}
												borderColor="white"
												resetButton={
													<div
														className="mini-view-control-button-wrapper"
														onClick={() => {
															if (zoomDisabled) return;
															resetView();
														}}
														style={{ position: 'absolute', margin: '2px', cursor: 'pointer' }}
													>
														<img className="reset-icon-wrapper" src={resetIcon} alt="" />
													</div>
												}
												overlay={
													superZoomEnabledRef?.current ? (
														<>
															{isSuperZoomPivotEnabled ? (
																<div
																	style={{
																		...miniViewZoomOverlayStyle,
																		display:
																			[
																				primaryCameraStateRef?.current?.currentPrimaryCamera,
																				primaryCameraStateRef?.current?.isChangingPrimaryCameraTo,
																			].includes(RobotPrimaryCamera.WIDE_CAM) ||
																			pivotStateRef.current.currentPivot
																				? 'flex'
																				: 'none',

																		backgroundColor: 'red',
																	}}
																/>
															) : null}

															<div
																style={{
																	...miniViewZoomOverlayStyle,
																	display:
																		[
																			primaryCameraStateRef?.current?.currentPrimaryCamera,
																			primaryCameraStateRef?.current?.isChangingPrimaryCameraTo,
																		].includes(RobotPrimaryCamera.WIDE_CAM) ||
																		pivotStateRef.current.currentPivot
																			? 'flex'
																			: 'none',
																	left: `${
																		100 * superZoomWithoutPivotPosition.deviation_percentage.x
																	}%`,
																	width: `${
																		100 * superZoomWithoutPivotPosition.dimension_percentage.width
																	}%`,
																}}
															/>
														</>
													) : null
												}
											>
												<div>{miniViewVideo}</div>
											</MiniMap>
											<div className="mini-view-controls">
												{[
													{
														icon: zoomOutIcon,
														callback: () =>
															primaryCameraStateRef?.current?.currentPrimaryCamera ===
																RobotPrimaryCamera.ZOOM_CAM &&
															transformComponentRef?.current?.instance?.transformState?.scale === 1
																? toggleSuperZoomRef?.current!()
																: utils.zoomOut(0.1),
													},
													{ icon: zoomInIcon, callback: () => utils.zoomIn(0.1) },
													{ icon: centerIcon, callback: () => utils.centerView() },
												].map((control, index) => (
													<div
														className="mini-view-control-button-wrapper"
														onClick={() => !zoomDisabled && control.callback()}
														key={`mini-view-control-${index}`}
													>
														<img className="icon-wrapper" src={control.icon} alt="" />
													</div>
												))}
												<div className="mini-view-control-zoom-dropdown">
													<div className="mini-view-control-zoom-dropdown-button">
														{`${
															(selectedCamera === RobotPrimaryCamera.WIDE_CAM ||
															primaryCameraStateRef.current.isChangingPrimaryCameraTo ||
															pivotStateRef.current.currentPivot
																? 0
																: 4) + (zoomScale ?? 0)
														}X`}
													</div>
													{superZoomEnabledRef.current ? (
														<div
															className={`mini-view-control-zoom-dropdown-content${
																primaryCameraStateRef.current.isChangingPrimaryCameraTo ||
																pivotStateRef.current.currentPivot
																	? '-disabled'
																	: ''
															}`}
														>
															<div
																className={`zoom-dropdown-content-option${
																	selectedCamera === RobotPrimaryCamera.WIDE_CAM ? '-selected' : ''
																}`}
																onClick={() =>
																	!zoomDisabled &&
																	(() => {
																		switch (primaryCameraStateRef?.current?.currentPrimaryCamera) {
																			case RobotPrimaryCamera.ZOOM_CAM:
																				resetView();
																				setWideCamZoomState(null);
																				toggleSuperZoomRef?.current!();
																				break;
																		}
																	})()
																}
															>
																{`${
																	selectedCamera === RobotPrimaryCamera.WIDE_CAM
																		? currentZoomScale
																		: 1
																}X`}
															</div>
															<div
																className={`zoom-dropdown-content-option${
																	selectedCamera === RobotPrimaryCamera.ZOOM_CAM ? '-selected' : ''
																}`}
																onClick={() =>
																	!zoomDisabled &&
																	(() => {
																		switch (primaryCameraStateRef?.current?.currentPrimaryCamera) {
																			case RobotPrimaryCamera.WIDE_CAM:
																				const scale =
																					(videoCroppingBoxStyle.width as number) /
																					(superZoomWithoutPivotPosition.dimension_percentage
																						.width *
																						(videoCroppingBoxStyle.width as number));
																				utils.setTransform(
																					-1 *
																						superZoomWithoutPivotPosition.deviation_percentage.x *
																						(videoCroppingBoxStyle.width as number) *
																						scale,
																					-1 *
																						superZoomWithoutPivotPosition.deviation_percentage.y *
																						(videoCroppingBoxStyle.height as number) *
																						scale,
																					scale
																				);
																				break;
																		}
																	})()
																}
															>
																{`${
																	selectedCamera === RobotPrimaryCamera.ZOOM_CAM
																		? currentZoomScale
																		: 5
																}X`}
															</div>
														</div>
													) : null}
												</div>
											</div>
										</div>
									}
									style={{
										top: 35,
										right: 10,
									}}
								/>
							</div>
							<div className="dynamic-media-video">
								<TransformComponent>
									<div>
										<RemoteNavOverlay />
									</div>
									{video}
									{objectDetectionEnabled && objectDetection}
								</TransformComponent>
							</div>
						</>
					)}
				</TransformWrapper>

				{cameraCalibrationToolEnabled ? (
					<VideoCalibrationOverlay
						deviationStep={cameraCalibrationDeviationStep}
						dimensions={{
							width: videoCroppingBoxStyle.width as number,
							height: videoCroppingBoxStyle.height as number,
						}}
						callbacks={{
							setDeviationX: (value) => {
								setDeviationPercentage((old) => ({
									...old,
									x: old.x + value,
								}));
							},
							setDeviationY: (value) => {
								setDeviationPercentage((old) => ({
									...old,
									y: old.y + value,
								}));
							},
							exportOutput: () => {
								const output = JSON.stringify({
									deviation_vector_percentage_16_9: [deviationPercentage.x, deviationPercentage.y],
								});
								console.log(output);
								downloadBlob(output, 'wide_camera_config.json', 'application/json');
							},
						}}
					/>
				) : null}
			</div>
		</Rnd>
	);
}

export default reduxConnector(DynamicMedia);
