import React, { useEffect } from 'react';

import Settings from 'components/settings';
import feedbackables from 'config/feedbackables.json';
import { AppContext } from 'context/appContext';
import useFeedbackController from 'hooks/useFeedbackController';
import useHotkeyController from 'hooks/useHotkeyController';
import useNavController from 'hooks/useNavController';
import useSettingsController from 'hooks/useSettingsController';
import FeedbackOverlay from 'pages/feedbackOverlay';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './common.scss';
import Lobby from './pages/lobby';
import Session from './pages/session';

const App: React.FC = () => {
	const settingsController = useSettingsController();
	const navController = useNavController();
	const feedbackController = useFeedbackController({
		feedbackables,
		settingsController,
	});
	useHotkeyController({ settingsController });

	// Disable dragging of images everywhere
	useEffect(() => {
		const callback = function (mutationsList: MutationRecord[]) {
			for (let mutation of mutationsList) {
				if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
					mutation.addedNodes.forEach((node: Node) => {
						if (node.nodeType === Node.ELEMENT_NODE) {
							const images = (node as HTMLElement).getElementsByTagName('img');
							for (let i = 0; i < images.length; i++) {
								images[i].draggable = false;
							}
						}
					});
				}
			}
		};
		const observer = new MutationObserver(callback);
		observer.observe(document, { attributes: true, childList: true, subtree: true });
	}, []);

	return (
		<AppContext.Provider value={{ settingsController, navController, feedbackController }}>
			<FeedbackOverlay />
			<Settings />
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Lobby />} />
					<Route path="/session" element={<Session />} />
				</Routes>
			</BrowserRouter>
		</AppContext.Provider>
	);
};

export default App;
