import React, { useEffect, useState } from 'react';
import './index.scss';

import playIcon from 'images/play.svg';
import blackPauseIcon from 'images/black-pause.svg';
import { ReactComponent as CloseIcon } from 'images/close.svg';

type Props = {
	isVisible: boolean;
	isSessionPaused: boolean;
	onClickResumeSession: () => void;
	onClickPauseSession: () => void;
	onClickEndSession: () => void;
	onClickCancel: () => void;
	/** In milliseconds */
	// timeSinceSessionPaused: number; // TODO: Implement this
};

/** Overlay to ask user to confirm whether she wants to actually end the session or pause it instead */
export default function PauseOrEndSessionOverlay({
	isVisible,
	isSessionPaused,
	onClickResumeSession,
	onClickEndSession,
	onClickPauseSession,
	onClickCancel,
}: Props) {
	const END_PAUSED_SESSION_IN_TIME = 900;
	const [endPausedSessionInTime, setEndPausedSessionInTime] = useState<number>(
		END_PAUSED_SESSION_IN_TIME
	);
	useEffect(() => {
		if (isSessionPaused) {
			const interval = setInterval(() => {
				setEndPausedSessionInTime((prevTime) => {
					if (prevTime <= 1) {
						onClickEndSession();
						clearInterval(interval); // clear the interval once session ends
						return 0;
					} else {
						return prevTime - 1;
					}
				});
			}, 1000);

			return () => {
				clearInterval(interval);
			};
		} else {
			setEndPausedSessionInTime(END_PAUSED_SESSION_IN_TIME);
		}
	}, [isSessionPaused]);
	return !isVisible ? null : (
		<div
			className={
				isSessionPaused
					? 'sessionEndPauseOpacity sessionEndPauseContainer'
					: 'sessionEndPauseContainer'
			}
		>
			<h1>{isSessionPaused ? 'On Hold' : `Ending the session`}</h1>
			<div className="endPauseButtonsContainer">
				<div className="circleTextContainer">
					<div
						className={isSessionPaused ? 'greenCircle blackCircle' : 'whiteCircle blackCircle'}
						onClick={() => (isSessionPaused ? onClickResumeSession() : onClickPauseSession())}
					>
						<img className="iconWrapper" src={isSessionPaused ? playIcon : blackPauseIcon} alt="" />
					</div>
					<div>{isSessionPaused ? 'Resume' : 'Pause'}</div>
				</div>
				<div className="circleTextContainer">
					<div className="redCircle blackCircle" onClick={() => onClickEndSession()}>
						<CloseIcon className="iconWrapper" />
					</div>
					<div>End</div>
				</div>
			</div>
			<div className="warningText">
				{isSessionPaused
					? `You will be auto-disconnected in ${Math.floor(endPausedSessionInTime / 60)
							.toString()
							.padStart(2, '0')}:${(endPausedSessionInTime % 60).toString().padStart(2, '0')}`
					: 'Are you sure that you want to end the session?'}
			</div>
			<div
				className={isSessionPaused ? 'displayNone' : 'cancelButtonContainer'}
				onClick={() => onClickCancel()}
			>
				CANCEL
			</div>
		</div>
	);
}
