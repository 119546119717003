import { SessionState } from 'GoBeWebRTC/types';
import { downloadBlob } from 'GoBeWebRTC/utils';
import { setParameter } from 'actions/setParam';
import { AppContext } from 'context/appContext';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { AppRootState } from 'reducers';
import { Size } from 'types';
import { sessionSoftRetryingFilter } from 'utils/styling';
import { SET_CONFIRM_AUTO_PARK, SET_IS_AUTO_PARKING } from '../../../../actions/types';
import Joystick from '../../navigation/joystick';
import VideoCalibrationOverlay from '../VideoCalibrationOverlay';
import { VIDEO_SEPARATOR_HEIGHT } from '../remoteVideo';
import NavigationNavOverlay from './NavigationNavOverlay';
import './index.scss';

const reduxConnector = connect(
	(state: AppRootState) => ({
		navCameraHeight: state.sessionState.navCameraHeight,
		confirmAutoPark: state.sessionState.confirmAutoPark,
		dockControllerStatus: state.sessionState.dockControllerStatus,
		cameraCalibrationToolEnabled: state.sessionState.cameraCalibrationToolEnabled,
		cameraCalibrationDeviationStep: state.sessionState.cameraCalibrationDeviationStep,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type PropsFromParent = {
	isStreamSynced: boolean;
	mediaStream: MediaStream;
	isJoystickMounted: boolean;
	navCameraRotation: number;
	navCameraDeviationPercentage?: { x: number; y: number };
	sessionState: SessionState;
};
type ComponentProps = PropsFromRedux & PropsFromParent;

const NAV_CAM_ASPECT_RATIO = 0.75; // width/height -> width is shorter

const NavigationVideo: React.FC<ComponentProps> = ({
	isStreamSynced,
	mediaStream,
	setParameter,
	navCameraHeight,
	isJoystickMounted,
	navCameraRotation,
	navCameraDeviationPercentage = { x: 0, y: 0 },
	sessionState,
	confirmAutoPark,
	dockControllerStatus,
	cameraCalibrationToolEnabled,
	cameraCalibrationDeviationStep,
}) => {
	const {
		navController: { onNavInputFocusChanged, lockActiveNavInput, unlockActiveNavInput },
	} = useContext(AppContext);
	const handleJoystickEnabled = (enabled: boolean) =>
		onNavInputFocusChanged('inputEnabled', enabled);
	const [deviationPercentage, setDeviationPercentage] = useState<{ x: number; y: number }>(
		navCameraDeviationPercentage
	);
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const [isAutoParking, setIsAutoParking] = useState(false);
	useEffect(() => {
		if (isStreamSynced && mediaStream) {
			setIsVideoLoading(true);
			videoRef.current!.srcObject = mediaStream;
		}
	}, [isStreamSynced, mediaStream]);

	const [isVideoLoading, setIsVideoLoading] = useState(true);

	const onCanPlay = () => {
		setIsVideoLoading(false);
	};

	const isSessionPaused = sessionState === 'Paused';
	const isSessionSoftRetrying = sessionState === 'SoftRetrying';
	useEffect(() => {
		if (isVideoLoading) return;

		if (isSessionPaused) {
			videoRef.current?.pause();
		} else {
			videoRef.current
				?.play()
				.catch((error) => console.error('Unable to play NavigationVideo', error));
		}
	}, [isSessionPaused, isVideoLoading]);

	const [isJoystickEnabled, setIsJoystickEnabled] = useState<boolean>(false);
	const [isJoystickHovered, setIsJoystickHovered] = useState<boolean>(false);

	const isVerticallyOriented = useMemo(() => {
		return Math.abs(navCameraRotation) % 180 === 90;
	}, [navCameraRotation]);

	const [windowDimensions, setWindowDimensions] = React.useState<Size>({
		height: window.innerHeight,
		width: window.innerWidth,
	});
	useEffect(() => {
		const handleResize = _.throttle(
			() => {
				setWindowDimensions({
					height: window.innerHeight,
					width: window.innerWidth,
				});
			},
			200,
			{ leading: true, trailing: true }
		);

		window.addEventListener('resize', handleResize);
		function handleStartAutoPark() {
			onParkclick();
		}
		document.addEventListener('firstStartAutoPark', handleStartAutoPark);
		document.addEventListener('keydown', onAutoParkCloseClick);
		return () => {
			window.removeEventListener('resize', handleResize);
			document.removeEventListener('startAutoPark', handleStartAutoPark);
			document.removeEventListener('keydown', onAutoParkCloseClick);
		};
	}, []);

	const containerDimensions = useMemo(() => {
		// Height must be at least 220px
		const height =
			navCameraHeight - VIDEO_SEPARATOR_HEIGHT < 220
				? 220
				: navCameraHeight - VIDEO_SEPARATOR_HEIGHT;
		const width = Math.floor(
			isVerticallyOriented ? height * NAV_CAM_ASPECT_RATIO : height / NAV_CAM_ASPECT_RATIO
		);

		return { size: { width, height } };
	}, [isVerticallyOriented, navCameraHeight]);

	let deviationX = useMemo(
		() =>
			deviationPercentage
				? (Math.abs(navCameraRotation) % 360 === 270 ? -1 : 1) *
				  deviationPercentage[isVerticallyOriented ? 'y' : 'x']
				: 0,
		[deviationPercentage, isVerticallyOriented, navCameraRotation]
	);

	let deviationY = useMemo(
		() =>
			deviationPercentage
				? (Math.abs(navCameraRotation) % 360 === 90 ? -1 : 1) *
				  deviationPercentage[isVerticallyOriented ? 'x' : 'y']
				: 0,
		[deviationPercentage, isVerticallyOriented, navCameraRotation]
	);

	const scale = useMemo(() => {
		const projectedDeviation = Math.abs(
			Math.abs(deviationX) > Math.abs(deviationY) ? deviationX : deviationY
		);
		return 1 / (1 - (2 * projectedDeviation) / 100);
	}, [deviationX, deviationY]);

	const componentStyle = useMemo((): React.CSSProperties => {
		return {
			width: containerDimensions.size.width,
			height: containerDimensions.size.height,
			left: (windowDimensions.width - containerDimensions.size.width) / 2,
		};
	}, [containerDimensions.size.height, containerDimensions.size.width, windowDimensions.width]);

	const videoStyle = useMemo((): React.CSSProperties => {
		const x =
			((deviationX < 0 ? -1 : 1) * (deviationX * scale * containerDimensions.size.width * 2)) / 100;
		let style: React.CSSProperties = {
			transform: `rotate(${navCameraRotation}deg) scale(${scale})`,
			transformOrigin: 'center',
			backgroundColor:
				['Paused', 'Retrying'].includes(sessionState) || isVideoLoading ? 'black' : 'unset',
			[isVerticallyOriented ? 'width' : 'height']: containerDimensions.size.height,
			...(deviationX
				? {
						[`margin${deviationX > 0 ? 'Right' : 'Left'}`]: `${x}px`,
				  }
				: {}),
			...(deviationY
				? {
						marginTop: `${(deviationY * scale * containerDimensions.size.height * 2) / 100}px`,
				  }
				: {}),
			...sessionSoftRetryingFilter(isSessionSoftRetrying),
		};

		return style;
	}, [
		navCameraRotation,
		scale,
		isVideoLoading,
		isVerticallyOriented,
		containerDimensions.size,
		deviationX,
		deviationY,
		isSessionSoftRetrying,
	]);

	const renderLoadingIndicator = () => {
		const isSessionRetrying = sessionState === 'Retrying';
		const isVideoLoadingIndicatorVisible = isVideoLoading || isSessionRetrying;
		return (
			isVideoLoadingIndicatorVisible && (
				<div className="loading-indicator-container">
					<div className="loading-indicator" />
				</div>
			)
		);
	};
	useEffect(() => {
		if (
			isAutoParking &&
			dockControllerStatus.stage !== 'STARTING' &&
			dockControllerStatus.stage !== 'DOCKING'
		) {
			document.dispatchEvent(new CustomEvent('stopAutoPark'));
			setIsAutoParking(false);
			setParameter('isAutoParking', SET_IS_AUTO_PARKING, false);
			setParameter('confirmAutoPark', SET_CONFIRM_AUTO_PARK, false);
		}
	}, [dockControllerStatus]);
	const onAutoParkCloseClick = () => {
		document.dispatchEvent(new CustomEvent('stopAutoPark'));
		setParameter('confirmAutoPark', SET_CONFIRM_AUTO_PARK, false);
		setIsAutoParking(false);
		setParameter('isAutoParking', SET_IS_AUTO_PARKING, false);
	};

	const onParkclick = () => {
		document.dispatchEvent(new CustomEvent('stopAutoPark'));
		document.dispatchEvent(new CustomEvent('startAutoPark'));
		setIsAutoParking(true);
		setParameter('isAutoParking', SET_IS_AUTO_PARKING, true);
	};

	const renderOverlayButtons = () => {
		return (
			<div className={`overlay-buttons ${!isAutoParking ? 'overlay-buttons-background ' : ''}`}>
				<div className="circleTextContainer" onClick={onAutoParkCloseClick}>
					<div
						className={`${!isAutoParking ? 'greyCircle' : 'autoParkClose'}  
						}`}
					>
						<CloseIcon className="iconWrapper" />
					</div>
				</div>
			</div>
		);
	};
	const handleHovered = (isHovered: boolean) => {
		setIsJoystickHovered(isHovered);
		handleJoystickEnabled(isHovered || isJoystickEnabled);
		if (isHovered) lockActiveNavInput('inputEnabled');
		else unlockActiveNavInput();
	};
	const handleEnabled = (isEnabled: boolean) => {
		setIsJoystickEnabled(isEnabled);
		handleJoystickEnabled(isEnabled || isJoystickHovered);
	};

	return (
		<div style={componentStyle} className="navigation-video">
			<NavigationNavOverlay />
			{cameraCalibrationToolEnabled ? (
				<VideoCalibrationOverlay
					deviationStep={cameraCalibrationDeviationStep}
					dimensions={{
						width: containerDimensions.size.width,
						height: containerDimensions.size.height,
					}}
					callbacks={{
						setDeviationX: (value) => {
							setDeviationPercentage((old) => ({
								...old,
								x: old.x + value,
							}));
						},
						setDeviationY: (value) => {
							setDeviationPercentage((old) => ({
								...old,
								y: old.y + value,
							}));
						},
						exportOutput: () => {
							const output = JSON.stringify({
								deviation_vector_percentage_4_3: [deviationPercentage.x, deviationPercentage.y],
							});
							console.log(output);
							downloadBlob(output, 'nav_camera_config.json', 'application/json');
						},
					}}
				/>
			) : null}
			<video
				ref={videoRef}
				onCanPlay={onCanPlay}
				playsInline
				autoPlay
				loop
				muted
				style={videoStyle}
			/>
			{renderLoadingIndicator()}
			{confirmAutoPark
				? renderOverlayButtons()
				: isJoystickMounted &&
				  !isVideoLoading && (
						<Joystick
							className="joystick-container"
							size={containerDimensions.size}
							handleHovered={handleHovered}
							handleEnabled={handleEnabled}
						/>
				  )}
		</div>
	);
};

export default reduxConnector(NavigationVideo);
